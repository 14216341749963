var render = function render(){
  var _vm$user, _vm$user$level;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mypage-navigation-wrap"
  }, [_c('v-card', {
    staticClass: "mb-20 mb-xl-10",
    attrs: {
      "color": "grey-fa"
    }
  }, [_c('div', {
    staticClass: "pa-20"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('h4', {
    staticClass: "tit tit--sm line-height-1"
  }, [_vm._v(_vm._s(_vm.user.name) + "님")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "to": "/mypage/myinfo",
      "text": "",
      "small": "",
      "color": "grey-6"
    }
  }, [_vm._v(" 회원정보 수정 "), _c('v-icon', {
    staticClass: "ml-4",
    attrs: {
      "size": "14"
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-6 my-lg-12"
  }), _c('div', {
    staticClass: "mb-4 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text grey-6--text"
  }, [_vm._v(" 회원등급 ")])]), _c('v-spacer', {
    staticClass: "d-none d-xl-block"
  }), _c('v-col', {
    staticClass: "ml-2 ml-lg-6 ml-xl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "v-chip--medium",
    attrs: {
      "color": "primary-50"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v(" " + _vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : (_vm$user$level = _vm$user.level) === null || _vm$user$level === void 0 ? void 0 : _vm$user$level.name) + " ")])])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', [_c('v-btn', {
    attrs: {
      "to": "/mypage/expert",
      "small": "",
      "block": "",
      "color": "primary"
    }
  }, [_vm._v(" 전문가 인증 ")])], 1)], 1)], 1)]), _c('ul', {
    staticClass: "mypage-navigation"
  }, [_c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/community",
      "active-class": "on"
    }
  }, [_c('i', {
    staticClass: "icon icon-column"
  }), _c('span', [_vm._v("작성 글")])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/bookmark",
      "active-class": "on"
    }
  }, [_c('i', {
    staticClass: "icon icon-bookmark"
  }), _c('span', [_vm._v("북마크한 글")])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/addiction",
      "active-class": "on"
    }
  }, [_c('i', {
    staticClass: "icon icon-addiction"
  }), _c('span', [_vm._v("테스트 결과")])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/survey",
      "active-class": "on"
    }
  }, [_c('i', {
    staticClass: "icon icon-survey"
  }), _c('span', [_vm._v("설문조사 결과")])])], 1), _c('li', {
    staticClass: "mypage-navigation__list"
  }, [_c('router-link', {
    staticClass: "mypage-navigation__link",
    attrs: {
      "to": "/mypage/inquiry",
      "active-class": "on"
    }
  }, [_c('i', {
    staticClass: "icon icon-inquiry"
  }), _c('span', [_vm._v("문의 내역")])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }