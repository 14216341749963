<template>
    <div>
        <div class="tit-wrap text-center">
            <h2 class="tit tit--lg">
                추천 콘텐츠
            </h2>
        </div>
        <div class="mb-n12 mb-sm-0">
            <v-row>
                <v-col cols="12" sm="4" class="mb-12 mb-sm-0" @click="move(board1)">
                    <board-item-primary :board="board1"/>
                </v-col>
                <v-col cols="12" sm="4" class="mb-12 mb-sm-0" @click="move(board2)">
                    <board-item-primary :board="board2"/>
                </v-col>
                <v-col cols="12" sm="4" class="mb-12 mb-sm-0" @click="move(board3)">
                    <board-item-primary :board="board3"/>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import BoardItemPrimary from "@/components/client/board/board-item--primary.vue";
export default {
    components: {
        BoardItemPrimary,
    },
    props: {
        board1: { type: Object, default: null },
        board2: { type: Object, default: null },
        board3: { type: Object, default: null },
    },
    methods: {
        move(board) {
            this.$router.push(`/content/solution/${board._id}`);
        },
    }
};
</script>

<style lang="scss" scoped></style>
