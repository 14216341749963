<template>
    <client-page class="app--main">
        <section class="section section--last">
            <v-container>
                <div class="tit-wrap text-center">
                    <h2 class="tit tit--lg">
                        중독{{ log.type === 'checkup' ? " 검진 ": " 테스트 " }} 결과
                    </h2>
                </div>
                <div class="mb-20 mb-lg-40">
                    <v-card elevation="0" rounded="" color="#FFEEE3" class="mb-20 mb-lg-40">
                        <div class="pa-20 pa-md-40">
                            <v-row align="center">
                                <v-col cols="12" md="4" class="mb-4 mb-md-0">
                                    <div class="d-flex flex-column align-center justify-enter text-center">
                                        <span class="chip--tit primary white--text mb-14 mb-lg-36">
                                            {{ log.type === 'checkup' ? "검진 ": "테스트 " }} 총점
                                        </span>
                                        <h2 class="tit tit--lg line-height-1 primary--text mb-14 mb-lg-36">
                                            <span class="countup">{{ log.totalScore }}</span>점
                                        </h2>
                                        <h4 class="tit tit--sm font-weight-medium word-keep-all">
                                            당신의 

                                            <!-- S::중독 테스트 노출 텍스트 -->
                                            <span v-if="log.type === 'test'"><span class="secondary--text">{{ log.addiction.subject }}</span> 테스트 </span>
                                            <!-- E::중독 테스트 노출 텍스트 -->

                                            <!-- S::중독검진 노출 텍스트 -->
                                            <span v-if="log.type === 'checkup'">검진</span> 
                                            <!-- E::중독검진 노출 텍스트 -->

                                            총점은 {{ log.totalScore }}점으로, <span class="primary--text">{{ log?.result?.status }}</span> 상태입니다. <br>
                                            <span class="primary--text">{{ log?.result?.need1 }} {{ log?.result?.need2 }}</span>
                                        </h4>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-card elevation="0" rounded="sm" color="white" class="mb-8 mb-lg-16">
                                        <div class="pa-20 pa-md-40 px-lg-60">
                                            <div class="v-progress-linear--primary-group">
                                                <div class="v-progress-linear--primary-wrap" v-for="(scoreGroup, index) in log.scoreGroups" :key="index">
                                                    <v-row align="center">
                                                        <v-col cols="auto">
                                                            <span class="chip--score">{{ scoreGroup.group }}</span>                                                    
                                                        </v-col>
                                                        <v-col>
                                                            <v-progress-linear class="v-progress-linear--primary" :value="(scoreGroup.score * 100) / scoreGroup.maxScore" buffer-value="100" />
                                                        </v-col>
                                                        <v-col cols="auto">
                                                            <div class="pl-lg-14">
                                                                <p class="min-w-lg-40px page-text grey-6--text text-right" style="min-width: 35px">
                                                                    <span class="countup">{{ scoreGroup.score }}</span>점
                                                                </p>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </div>
                                        </div>
                                    </v-card>
                                    <v-card elevation="0" rounded="sm" color="white">
                                        <div class="pa-20 pa-md-40 px-lg-60">
                                            <div class="v-progress-linear--primary-wrap v-progress-linear--primary-wrap--lg">
                                                <v-row align="center">
                                                    <v-col cols="auto">
                                                        <span class="chip--score primary--text">총점</span>                                                    
                                                    </v-col>
                                                    <v-col>
                                                        <v-progress-linear class="v-progress-linear--primary" :value="log.totalScore" buffer-value="100" />
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <h4 class="tit tit--sm text-right total-score">
                                                            <span class="countup">{{ log.totalScore }}</span>점
                                                        </h4>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                    <div v-html="log?.result?.content"></div>
                </div>
                <div class="mb-20 mb-lg-40">
                    <v-row class="row--large" justify="center">
                        <v-col cols="auto">
                            <a target="_blank" class="link--icon" @click="shareTwitter">
                                <i class="icon icon-twitter"></i>
                            </a>
                        </v-col>
                        <v-col cols="auto">
                            <a target="_blank" class="link--icon" @click="shareFacebook">
                                <i class="icon icon-facebook"></i>
                            </a>
                        </v-col>
                        <v-col cols="auto">
                            <a target="_blank" class="link--icon" @click="shareKakao">
                                <i class="icon icon-kakao"></i>
                            </a>
                        </v-col>
                        <v-col cols="auto">
                            <a target="_blank" class="link--icon" @click="shareInstagram">
                                <i class="icon icon-instar"></i>
                            </a>
                        </v-col>
                        <v-col cols="auto">
                            <a target="_blank" class="link--icon" @click="copyClipboard">
                                <i class="icon icon-link"></i>
                            </a>
                        </v-col>
                    </v-row>
                </div>
                <div class="d-flex justify-center">
                    <v-btn large color="primary" class="min-w-lg-200px">
                        다른 테스트 하러가기
                    </v-btn>
                </div>

                <v-divider class="my-40 my-lg-60" />

                <recommend-list :board1="log.recommendContent1" :board2="log.recommendContent2" :board3="log.recommendContent3"/>

            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import AddictionQuestionView from "@/components/client/addiction/addiction-questions-view.vue";
import RecommendList from "@/components/client/addiction/recommend-list.vue";

export default {
    components: {
        ClientPage,
        AddictionQuestionView,
        RecommendList,
    },

    data() {
        return {
            tab: 0,
            log: {},

            type: "number",
            number: 0,
            duration: 0,
            offset: 0,
            easing: "easeInOutCubic",
        };
    },

    async mounted() {
        await this.init();
    },

    methods: {
        async init() {
            if (this.$route.params._log) {
                let { log } = await api.v1.me.addiction.get({ _id: this._log });
                console.log(log);
                this.log = log;
            };
            setTimeout( function() {
                // countup
                const animationDuration = 2000;
                const frameDuration = 1000 / 60;
                const totalFrames = Math.round( animationDuration / frameDuration );
                const easeOutQuad = t => t * ( 2 - t );

                const animateCountUp = el => {
                let frame = 0;
                const countTo = parseInt( el.innerHTML, 10 );
                const counter = setInterval( () => {
                    frame++;
                    const progress = easeOutQuad( frame / totalFrames );
                    const currentCount = Math.round( countTo * progress );

                    if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
                    el.innerHTML = currentCount;
                    }

                    if ( frame === totalFrames ) {
                    clearInterval( counter );
                    }
                }, frameDuration );
                };

                const countupEls = document.querySelectorAll( '.countup' );
                countupEls.forEach( animateCountUp );
		    }, 10);
        },

        desplayCode(code) {
            switch(code) {
                case "sex" :
                    return "성"
                case "drug" : 
                    return "약물"
                case "gambling" : 
                    return "약물"
                case "game" : 
                    return "게임"
                case "internet" : 
                    return "인터넷"
            }
        },
        shareTwitter() {
            try {
                let shareUrl = location.href;

                window.open(`http://twitter.com/share?url=${shareUrl}`, "tweetPop", "width=486, height=600,scrollbars=yes")
            } catch (error) {
                alert(error.message);
            }
        },
        shareKakao() {
            try{
                let key = "5713f81f59be4edd95e80de06bbf69f6"; // 웹 API 키
                if(!key) throw new Error("카카오에서 JAVASCRIPT API 키를 발급받아 전달해주세요.(+ 카카오에 WEB 정보를 등록해주세요)");

                let shareUrl = location.href;

                if(!Kakao.isInitialized()) Kakao.init(key);
                Kakao.Share.sendDefault({
                    objectType: 'feed',
                    content: {
                        title: "안녕하세요 test",
                        description: '#케익 #딸기 #삼평동 #카페 #분위기 #소개팅',
                        imageUrl: `https://${location.host}/${this.result.addiction.thumb}`,
                        link: {
                            // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
                            // mobileWebUrl: 'https://developers.kakao.com',
                            mobileWebUrl: shareUrl,
                            // webUrl: 'https://developers.kakao.com',
                            webUrl: shareUrl,
                        },
                    },
                });
            }
            catch(error){
                alert(error.message);
            }
        },
        shareFacebook() {
            try {
                let shareUrl = location.href;

                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, "facebookPop", "width=486, height=600,scrollbars=yes") 
            } catch (error) {
                alert(error.message);
            }
        },
        shareInstagram() {
            try {
                let client_id = "596088568743396"; // 클라이언트 아이디
                if (!client_id) throw new Error("인스타그램 클라이언트 아이디를 발급받아 전달해주세요.");

                let shareUrl = location.href;

                location.href = `https://api.instagram.com/oauth/authorize/?client_id=${client_id}&redirect_uri=${shareUrl}&response_type=code`;
            } catch (error) {
                alert(error.message);
            }
        },
        copyClipboard() {
            try {
                if (!window?.navigator?.clipboard?.writeText) throw new Error("SSL 환경에서만 클립보드 복사 가능합니다");

                let shareUrl = location.href;
                console.log(shareUrl);

                window.navigator.clipboard.writeText(shareUrl);

                alert("복사 되었습니다");
            } catch (error) {
                alert(error.message);
            }
        },
    },
    computed: {
        target() {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options() {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
        _log() {
            return this.$route.params._log
        }
    },
};
</script>

<style lang="scss" scoped>
.total-score{
    min-width: 42px;
}
.link--icon{
    display: block;
    border-radius: 50%;
    overflow: hidden;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .total-score{
        min-width: 53px;
    }
}
@media (min-width:1200px){
}

</style>
