<template>
    <div>
        <div class="board__view mb-20 mb-lg-40">
            <div class="board__view__row">
                <p class="page-text font-weight-medium">
                    {{ inquiry.subject }}
                </p>
            </div>
            <div class="board__view__row">
                <div class="d-flex flex-wrap align-center">
                    <div class="d-flex align-center mr-8 mr-lg-16">
                        <p class="page-text--sm grey-9--text mr-4 mr-lg-8">작성자 :</p>
                        <board-user-level :user="inquiry.user" textStyle="page-text--sm grey-9--text" />
                    </div>
                    <div class="d-flex align-center">
                        <i class="icon icon-clock mr-4 mr-lg-8"></i>
                        <p class="page-text page-text--sm grey-9--text">
                            {{ $dayjs(inquiry.createdAt).format("YYYY.MM.DD") }}
                        </p>
                    </div>
                </div> 
            </div>
            <div class="board__view__row min-h-160px">
                <p class="page-text grey-6--text line-height-15">
                    {{ inquiry.content }}
                </p>
            </div>
            <div v-if="inquiry.reply.content" class="board__view__row grey-fa">
                <v-chip color="#FFEEE3" style="border-radius: 6px" class="mb-4 mb-lg-8">
                    <span class="primary--text font-weight-medium">관리자 답변</span>
                </v-chip>
                <p v-html="inquiry.reply.content" class="page-text grey-6--text line-height-15"></p>
            </div>
        </div>
        <v-row>
            <v-col cols="auto">
                <v-btn @click="$router.go(-1)" outlined color="primary" class="h-lg-40px">리스트</v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-row v-if="!inquiry.reply.content" class="row--x-small">
                    <v-col cols="auto">
                        <v-btn color="grey-6" class="h-lg-40px" :to="`/mypage/inquiry/${_inquiry}/edit`">
                            <span class="white--text font-size-14">수정</span>
                        </v-btn>
                    </v-col>
                    <!-- <v-col cols="auto">
                        <v-btn outlined color="grey-b3" class="h-lg-40px">
                            <span class="grey-6--text font-size-14">삭제</span>
                        </v-btn>
                    </v-col> -->
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from '@/api'
import BoardTit from "@/components/client/board/board-item__tit.vue";
import BoardUserLevel from "@/components/client/board/board-user-level.vue";

export default {
    components: {
        BoardTit,
        BoardUserLevel,
    },

    data() {
        return {
            inquiry: {}
        };
    },

    async mounted() {
        await this.init()
    },

    methods: {
        async init() {
            let { inquiry } = await api.v1.center.inquirys.get({ _id: this._inquiry })
            this.inquiry = inquiry;
        },
    },

    computed: {
        _inquiry() {
            return this.$route.params._inquiry;
        }
    }
};
</script>

<style lang="scss" scoped>
</style>