<template>
    <v-card rounded color="grey-fa" elevation="0" class="w-100">
        <div class="pa-20 pa-md-30 py-lg-80 w-100 d-flex flex-column align-center text-center">
            <h4 class="tit tit--sm font-weight-medium grey-6--text mb-12 mb-lg-40">
                개인정보 확인을 위해 비밀번호를 입력해주세요.
            </h4>
            <v-row justify="center" class="row--x-small w-100">
                <v-col cols="9" md="auto">
                    <v-password-field v-model="password" placeholder="비밀번호를 입력해주세요." outlined hide-details class="v-input--small w-100 w-md-240px" />
                </v-col>
                <v-col cols="3" md="auto">
                    <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6" @click="confirm">
                        <span class="white--text font-size-14">확인</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";

export default {
    components: {
        VPasswordField,
    },

    data() {
        return {
            password: null,
        };
    },

    methods: {
        async confirm() {
            console.log("ss");
            let { success } = await api.v1.me.confirm({ params: { password: CryptoAES.encrypt(this.password) } });
            if (success) {
                this.$emit("confirm");
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
