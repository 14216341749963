<template>
    <my-page tit="작성 글">
        <v-tabs color="grey-9" hide-slider class="v-tabs--punch mb-20 mb-lg-40">
            <v-tab>영상</v-tab>
            <v-tab>칼럼</v-tab>
        </v-tabs>
        <div class="mb-20 mb-lg-40">
            <v-row class="row--small ma-lg-n8">
                <v-spacer />
                <v-col cols="12" md="auto" class="pa-lg-8">
                    <v-row class="row--x-small" align="center">
                        <v-col cols="auto" >
                            <p class="page-text grey-6--text">
                                승인상태 선택
                            </p>
                        </v-col>
                        <v-col md="auto">
                            <v-select v-model="filter.approvalStatus" :items="approvalStatus" class="v-input--small w-md-100px" outlined hide-details/>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="auto" class="pa-lg-8">
                    <v-row class="row--x-small search--primary">
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="auto">
                            <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-120px" outlined hide-details @input="search(true)"></v-select>
                        </v-col>
                        <v-col cols="9" md="auto">
                            <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." @input="search(true)"/>
                        </v-col>
                        <v-col cols="3" md="auto">
                            <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6">
                                <span class="white--text font-size-14" @click="search(true)">검색</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-row class="row--x-small my-n10 my-lg-n20">

            <!-- S::영상, 칼럼 -->
            <v-col v-for="board in boards" :key="board._id" cols="6" md="4" class="py-10 py-lg-20" @click="move(board)">
                <board-item-primary :board="board"/>
            </v-col>
            <!-- E::영상, 칼럼 -->

        </v-row>
        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search()"/>
        </div>
    </my-page>
</template>

<script>
import api from '@/api'
import MyPage from "@/pages/client/templates/MyPage.vue";
import SearchPrimary from "@/components/client/board/search--primary.vue";
import BoardItemPrimary from "@/components/client/board/board-item--primary.vue";

export default {
    components: {
        MyPage,
        SearchPrimary,
        BoardItemPrimary,
    },
    props: {
    },
    data() {
        return {
            page: 1,
            limit: 6,
            pageCount: 1,
            category: null,

            code: "column",

            filter: {
                code: "column",
                searchKey: this.$route.query.searchKey || "subjectcontent",
                searchValue: this.$route.query.searchValue || null,
                approvalStatus: this.$route.query.approvalStatus || null,
                _category: this.$route.query._category || null
            },

            searchKeys: [
                { text: "제목+내용", value: "subjectcontent" },
                { text: "제목", value: "subject" },
            ],

            approvalStatus:[
                { text: "전체", value: null },
                { text: "승인", value: "승인" },
                { text: "미승인", value: "미승인" },
                { text: "승인불가", value: "승인불가" },
            ],

            boards: null
        };
    },
    async mounted() {
        await this.init()
    },
    methods: {
        async init() {
            let { category } = await api.v1.boards.categories.get({code: this.filter.code})
            this.category = category;
            await this.search()
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
                return
            }
        
            var { summary, boards } = await api.v1.me.boards.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: {...this.filter}
            });

            this.boards = boards;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        input(_category) {
            this.filter._category = _category;
            this.$router.push({
                query: { ...this.filter }
            })
            this.search();
        },
        move(board) {
            this.$router.push(`/mypage/column/${board._id}`)
        }
    }
};
</script>
