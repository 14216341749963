<template>
    <client-page>

        <section class="section section--last">
            <v-container>

                <v-row class="row--large flex-xl-nowrap">
                    <v-col cols="12" xl="auto" class="mb-20 mb-lg-40 mb-xl-0">
                        <mypage-navigation />
                    </v-col>
                    <v-col cols="12" xl="">
                        <div class="mypage-contents">
                            <h2 v-if="tit" class="tit mb-20 mb-lg-40">
                                {{tit}}
                            </h2>
                            <slot />
                        </div>
                    </v-col>
                </v-row>
                
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

export default {
    props: {
        tit: String,
    },
    components: {
        ClientPage,
        MypageNavigation,
    },
    created(){
        if(this.logoff) this.$router.push(`/login`);
    },
    data() {
        return {
        };
    },
    computed: {
        accessToken() {
            return !this.$store.state.accessToken;
        },
        logoff(){
            return !this.$store.state.accessToken;
        },
    }
};
</script>

<style lang="scss" scoped>
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>