var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section section--last"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--large flex-xl-nowrap"
  }, [_c('v-col', {
    staticClass: "mb-20 mb-lg-40 mb-xl-0",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('mypage-navigation')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('div', {
    staticClass: "mypage-contents"
  }, [_vm.tit ? _c('h2', {
    staticClass: "tit mb-20 mb-lg-40"
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")]) : _vm._e(), _vm._t("default")], 2)])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }