var render = function render(){
  var _vm$log, _vm$log$result, _vm$log2, _vm$log2$result, _vm$log3, _vm$log3$result, _vm$log4, _vm$log4$result;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('section', {
    staticClass: "section section--last"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(" 중독" + _vm._s(_vm.log.type === 'checkup' ? " 검진 " : " 테스트 ") + " 결과 ")])]), _c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('v-card', {
    staticClass: "mb-20 mb-lg-40",
    attrs: {
      "elevation": "0",
      "rounded": "",
      "color": "#FFEEE3"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-40"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-4 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-center justify-enter text-center"
  }, [_c('span', {
    staticClass: "chip--tit primary white--text mb-14 mb-lg-36"
  }, [_vm._v(" " + _vm._s(_vm.log.type === 'checkup' ? "검진 " : "테스트 ") + " 총점 ")]), _c('h2', {
    staticClass: "tit tit--lg line-height-1 primary--text mb-14 mb-lg-36"
  }, [_c('span', {
    staticClass: "countup"
  }, [_vm._v(_vm._s(_vm.log.totalScore))]), _vm._v("점 ")]), _c('h4', {
    staticClass: "tit tit--sm font-weight-medium word-keep-all"
  }, [_vm._v(" 당신의 "), _vm.log.type === 'test' ? _c('span', [_c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.log.addiction.subject))]), _vm._v(" 테스트 ")]) : _vm._e(), _vm.log.type === 'checkup' ? _c('span', [_vm._v("검진")]) : _vm._e(), _vm._v(" 총점은 " + _vm._s(_vm.log.totalScore) + "점으로, "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s((_vm$log = _vm.log) === null || _vm$log === void 0 ? void 0 : (_vm$log$result = _vm$log.result) === null || _vm$log$result === void 0 ? void 0 : _vm$log$result.status))]), _vm._v(" 상태입니다. "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s((_vm$log2 = _vm.log) === null || _vm$log2 === void 0 ? void 0 : (_vm$log2$result = _vm$log2.result) === null || _vm$log2$result === void 0 ? void 0 : _vm$log2$result.need1) + " " + _vm._s((_vm$log3 = _vm.log) === null || _vm$log3 === void 0 ? void 0 : (_vm$log3$result = _vm$log3.result) === null || _vm$log3$result === void 0 ? void 0 : _vm$log3$result.need2))])])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "mb-8 mb-lg-16",
    attrs: {
      "elevation": "0",
      "rounded": "sm",
      "color": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-40 px-lg-60"
  }, [_c('div', {
    staticClass: "v-progress-linear--primary-group"
  }, _vm._l(_vm.log.scoreGroups, function (scoreGroup, index) {
    return _c('div', {
      key: index,
      staticClass: "v-progress-linear--primary-wrap"
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "chip--score"
    }, [_vm._v(_vm._s(scoreGroup.group))])]), _c('v-col', [_c('v-progress-linear', {
      staticClass: "v-progress-linear--primary",
      attrs: {
        "value": scoreGroup.score * 100 / scoreGroup.maxScore,
        "buffer-value": "100"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "pl-lg-14"
    }, [_c('p', {
      staticClass: "min-w-lg-40px page-text grey-6--text text-right",
      staticStyle: {
        "min-width": "35px"
      }
    }, [_c('span', {
      staticClass: "countup"
    }, [_vm._v(_vm._s(scoreGroup.score))]), _vm._v("점 ")])])])], 1)], 1);
  }), 0)])]), _c('v-card', {
    attrs: {
      "elevation": "0",
      "rounded": "sm",
      "color": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-40 px-lg-60"
  }, [_c('div', {
    staticClass: "v-progress-linear--primary-wrap v-progress-linear--primary-wrap--lg"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "chip--score primary--text"
  }, [_vm._v("총점")])]), _c('v-col', [_c('v-progress-linear', {
    staticClass: "v-progress-linear--primary",
    attrs: {
      "value": _vm.log.totalScore,
      "buffer-value": "100"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm text-right total-score"
  }, [_c('span', {
    staticClass: "countup"
  }, [_vm._v(_vm._s(_vm.log.totalScore))]), _vm._v("점 ")])])], 1)], 1)])])], 1)], 1)], 1)]), _c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$log4 = _vm.log) === null || _vm$log4 === void 0 ? void 0 : (_vm$log4$result = _vm$log4.result) === null || _vm$log4$result === void 0 ? void 0 : _vm$log4$result.content)
    }
  })], 1), _c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('v-row', {
    staticClass: "row--large",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    staticClass: "link--icon",
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": _vm.shareTwitter
    }
  }, [_c('i', {
    staticClass: "icon icon-twitter"
  })])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    staticClass: "link--icon",
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": _vm.shareFacebook
    }
  }, [_c('i', {
    staticClass: "icon icon-facebook"
  })])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    staticClass: "link--icon",
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": _vm.shareKakao
    }
  }, [_c('i', {
    staticClass: "icon icon-kakao"
  })])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    staticClass: "link--icon",
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": _vm.shareInstagram
    }
  }, [_c('i', {
    staticClass: "icon icon-instar"
  })])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    staticClass: "link--icon",
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": _vm.copyClipboard
    }
  }, [_c('i', {
    staticClass: "icon icon-link"
  })])])], 1)], 1), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "min-w-lg-200px",
    attrs: {
      "large": "",
      "color": "primary"
    }
  }, [_vm._v(" 다른 테스트 하러가기 ")])], 1), _c('v-divider', {
    staticClass: "my-40 my-lg-60"
  }), _c('recommend-list', {
    attrs: {
      "board1": _vm.log.recommendContent1,
      "board2": _vm.log.recommendContent2,
      "board3": _vm.log.recommendContent3
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }