var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board__view mb-20 mb-lg-40"
  }, [_c('div', {
    staticClass: "board__view__row"
  }, [_c('p', {
    staticClass: "page-text font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.inquiry.subject) + " ")])]), _c('div', {
    staticClass: "board__view__row"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap align-center"
  }, [_c('div', {
    staticClass: "d-flex align-center mr-8 mr-lg-16"
  }, [_c('p', {
    staticClass: "page-text--sm grey-9--text mr-4 mr-lg-8"
  }, [_vm._v("작성자 :")]), _c('board-user-level', {
    attrs: {
      "user": _vm.inquiry.user,
      "textStyle": "page-text--sm grey-9--text"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('i', {
    staticClass: "icon icon-clock mr-4 mr-lg-8"
  }), _c('p', {
    staticClass: "page-text page-text--sm grey-9--text"
  }, [_vm._v(" " + _vm._s(_vm.$dayjs(_vm.inquiry.createdAt).format("YYYY.MM.DD")) + " ")])])])]), _c('div', {
    staticClass: "board__view__row min-h-160px"
  }, [_c('p', {
    staticClass: "page-text grey-6--text line-height-15"
  }, [_vm._v(" " + _vm._s(_vm.inquiry.content) + " ")])]), _vm.inquiry.reply.content ? _c('div', {
    staticClass: "board__view__row grey-fa"
  }, [_c('v-chip', {
    staticClass: "mb-4 mb-lg-8",
    staticStyle: {
      "border-radius": "6px"
    },
    attrs: {
      "color": "#FFEEE3"
    }
  }, [_c('span', {
    staticClass: "primary--text font-weight-medium"
  }, [_vm._v("관리자 답변")])]), _c('p', {
    staticClass: "page-text grey-6--text line-height-15",
    domProps: {
      "innerHTML": _vm._s(_vm.inquiry.reply.content)
    }
  })], 1) : _vm._e()]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-lg-40px",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [!_vm.inquiry.reply.content ? _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-lg-40px",
    attrs: {
      "color": "grey-6",
      "to": `/mypage/inquiry/${_vm._inquiry}/edit`
    }
  }, [_c('span', {
    staticClass: "white--text font-size-14"
  }, [_vm._v("수정")])])], 1)], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }