<template>
    <client-page>

        <section class="section--first section--last">
            <v-container>

                <board-list />
                <!-- <board-view /> -->
                
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import BoardList from "@/components/client/board/skin/notice/list.vue";
import BoardView from "@/components/client/board/skin/notice/view.vue";

export default {
    components: {
        ClientPage,
        BoardList,
        BoardView,
    },

    data() {
        return {
        };
    },
};
</script>
