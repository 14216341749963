var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('section', {
    staticClass: "section section--last"
  }, [_c('v-container', [_c('div', {
    staticClass: "board__input"
  }, [_c('div', {
    staticClass: "mb-8 mb-lg-20 text-right"
  }, [_c('p', {
    staticClass: "page-text page-text--sm grey-6--text line-height-1"
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("*")]), _vm._v(" 필수입력 항목입니다.")])]), _c('div', {
    staticClass: "form--primary"
  }, [_c('form-row', {
    attrs: {
      "tit": "제목",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "제목을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "카테고리",
      "pointer": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.categoryItems,
      "placeholder": "--선택--",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board._category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "_category", $$v);
      },
      expression: "board._category"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "썸네일",
      "pointer": ""
    }
  }, [_c('v-file-input', {
    attrs: {
      "placeholder": "사이즈 (520*372)",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.board, "thumb", $$v);
      },
      expression: "board.thumb"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "칼럼 내용",
      "pointer": ""
    }
  }, [_c('naver-smarteditor', {
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-120px min-w-lg-160px",
    attrs: {
      "outlined": "",
      "color": "grey-b3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey-6--text"
  }, [_vm._v("리스트")])]), _c('v-btn', {
    staticClass: "min-w-120px min-w-lg-160px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v(" 수정하기 ")])], 1)])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }