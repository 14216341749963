<template>
    <div>

        <table class="board-list board-list--main mb-20 mb-lg-40">
            <thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:70px;">No.</th>
                    <th>제목</th>
                    <th style="width:110px;">답변여부</th>
                    <th style="width:110px;">날짜</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="inquiry in inquirys" :key="inquiry._id" @click="$router.push(`/mypage/inquiry/${inquiry._id}`)">
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ (page - 1) * limit + (+inquirys.indexOf(inquiry) + 1) }}
                    </td>
                    <td align="center" class="board-list__tit">
                        <p class="grey-6--text page-text ellip">
                            {{ inquiry.subject }}
                        </p>
                    </td>
                    <td align="center" class="board-list__txt">
                        <span v-if="inquiry.reply.content" class="page-text page-text--sm primary-text">답변완료</span>
                        <span v-else class="page-text page-text--sm">대기중</span>
                    </td>
                    <td align="center" class="board-list__txt">
                        <span class="page-text page-text--sm">{{ $dayjs(inquiry.createdAt).format("YYYY.MM.DD") }}</span>
                    </td>
                </tr>
            </tbody>
        </table>

        <v-row class="row--x-small">
            <v-spacer />   
            <v-col cols="auto">
                <v-btn outlined color="primary" class="h-lg-40px" to="/mypage/inquiry/create">문의하기</v-btn>
            </v-col>                  
        </v-row>

        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search()"/>
        </div>

    </div>
</template>

<script>
import api from '@/api'

export default {
    components: {
    },
    data() {
        return {
            page: 1,
            pageCount: 0,
            limit: 10,

            inquirys: []
        };
    },
    async mounted() {
        await this.init()
    },
    methods: {
        async init() {
            await this.search()
        },
        async search(){
            var { summary, inquirys } = await api.v1.center.inquirys.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
            });

            this.inquirys = inquirys;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    }
};
</script>

<style lang="scss" scoped>
</style>