<template>
        <client-page class="app--main">
            <section class="section section--last">
                <v-container>
                    <div class="board__input">
                        <h3 v-if="code === 'certification'" class="font-size-20 font-size-lg-28 mb-20">인증 게시판</h3>
                        <h3 v-if="code === 'free'" class="font-size-20 font-size-lg-28 mb-20">자유 게시판</h3>
                        <h3 v-if="code === 'challenge'" class="font-size-20 font-size-lg-28 mb-20">챌린지 게시판</h3>
                
                        <div class="mb-8 mb-lg-20 text-right">
                            <p class="page-text page-text--sm grey-6--text line-height-1"><span class="primary--text">*</span> 필수입력 항목입니다.</p>
                        </div>
                        <div class="form--primary">
                            <form-row tit="제목" pointer>
                                <v-text-field v-model="board.subject" placeholder="제목을 입력하세요." outlined hide-details />
                            </form-row>
                            <form-row tit="카테고리" pointer>
                                <v-select v-model="board._category" :items="categoryItems" placeholder="--선택--" outlined hide-details />
                            </form-row>
                            <form-row tit="해시태그" pointer>
                                <v-text-field v-model="board.hashTags" placeholder="ex) 건강, 행복" outlined hide-details />
                            </form-row>
                            <form-row tit="내용" pointer>
                                <naver-smarteditor v-model="board.content" />
                            </form-row>
                        </div>
                
                        <div class="v-btn--group">
                            <v-btn @click="$router.go(-1)" outlined color="primary" class="min-w-120px min-w-lg-160px">
                                리스트
                            </v-btn>
                            <v-btn color="primary" class="min-w-120px min-w-lg-160px" @click="save()">
                                수정하기
                            </v-btn>
                        </div>
                    </div>
                </v-container>
            </section>
        </client-page>
</template>

<script>
import api from "@/api";
import FormRow from "@/components/dumb/form-row.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";

export default {
    components: {
        FormRow,
        NaverSmarteditor,
        ClientPage,
    },

    data() {
        return {
            category: {
                children: [],
            },

            board: {
                _category: null,

                subject: null,
                code: null,

                hashTags: null,

                content: null,
            },
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { category } = await api.v1.boards.categories.get({ code: this.code });
            let { board } = await api.v1.me.boards.get({ _id: this.$route.params._board });
            this.category = category;
            this.board = board;
        },
        validate() {
            try {
                if (!this.board.subject) throw new Error("제목을 입력해주세요");
                if (!this.board._category) throw new Error("카테고리을 선택해주세요");
                if (!this.board.content) throw new Error("내용을 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (this.validate()) {
                let { board } = await api.v1.me.boards.put({ ...this.board, code: this.code });

                alert("저장되었습니다");

                this.$router.go(-1);
            }
        },
    },
    computed: {
        code() {
            return this.$route.query.code;
        },
        categoryItems() {
            return this.category.children.map((el) => {
                return { text: el.name, value: el._id };
            });
        },
    },
    watch: {
        async code() {
            await this.init();
        },
    },
};
</script>

<style lang="scss" scoped></style>
