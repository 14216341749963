<template>
    <client-page>

        <section class="section--first section--last">
            <v-container>

                <h2 class="tit tit--lg line-height-1 text-center mb-20 mb-lg-40">
                    문의사항
                </h2>

                <div class="mb-8 mb-lg-20 text-right">
                    <p class="page-text page-text--sm grey-6--text line-height-1">
                        <span class="primary--text">*</span> 필수입력 항목입니다.
                    </p>
                </div>
                <div class="form--primary">
                    <form-row tit="제목" pointer>
                        <v-text-field v-model="inquiry.subject" placeholder="제목을 입력하세요." outlined hide-details />
                    </form-row>
                    <form-row tit="작성자" pointer>
                        <v-text-field v-model="inquiry.writer.name" placeholder="작성자를 입력하세요." outlined hide-details />
                    </form-row>
                    <form-row tit="연락처" pointer>
                        <v-text-field v-model="inquiry.writer.phone" placeholder="전화번호를 입력하세요." outlined hide-details />
                    </form-row>
                    <form-row tit="이메일" pointer>
                        <v-row class="row--x-small" align="center">
                            <v-col cols="12" md="6">
                                <v-text-field v-model="email1" placeholder="이메일을 입력하세요." outlined hide-details />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-combobox v-model="email2" prefix="@" :items="emailItems" outlined hide-details />
                            </v-col>
                        </v-row>
                    </form-row>
                    <form-row tit="내용" pointer>
                        <v-textarea v-model="inquiry.content" placeholder="내용을 입력하세요." rows="8" outlined hide-details />
                    </form-row>
                    <form-row tit="파일첨부" pointer>
                        <v-file-input v-model="inquiry.file" placeholder="파일을 첨부하세요." outlined hide-details />
                    </form-row>
                </div>
                <div class="form__agreement">
                    <v-checkbox v-model="agree" label="개인정보 수집ㆍ이용 동의" hide-details />
                    <button onclick="window.open('/privacy-policy', '', 'width=483,height=700');" class="form__agreement__btn">전문보기</button>
                </div> 
                
                <div class="v-btn--group">
                    <!-- <v-btn outlined color="primary" class="min-w-120px min-w-lg-160px">
                        리스트
                    </v-btn> -->
                    <v-btn color="primary" class="min-w-120px min-w-lg-160px" @click="save">
                        작성하기
                    </v-btn>
                </div>
            </v-container>
        </section>

    </client-page>
</template>

<script>
import api from '@/api'
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import FormRow from "@/components/dumb/form-row.vue";

export default {
    components: {
        ClientPage,
        FormRow
    },

    data() {
        return {
            emailItems: ["gmail.com", "naver.com", "nate.com"],

            inquiry: {
                subject: null,
                writer: {
                    name: null,
                    phone: null,
                    email: null,
                },
                content: null,
                file: null,
                type: "nomember"
            },

            agree: false,

            email1: null,
            email2: null,
        };
    },
    methods: {
        async save() {
            if (this.validate()) {
                let { inquiry } = await api.v1.center.inquirys.post({ ...this.inquiry });
                if (this.inquiry.file) await api.v1.center.inquirys.postFile(inquiry, this.inquiry.file);
                alert("문의되었습니다.");
                this.$router.go(-1);
            }
        },
        validate() {
            try {
                if (!this.inquiry.subject) throw new Error("제목을 입력해주세요");
                if (!this.inquiry.writer.name) throw new Error("작성자 이름을 입력해주세요");
                if (!this.inquiry.writer.phone) throw new Error("작성자 연락처를 입력해주세요");
                if (!this.inquiry.writer.email) throw new Error("작성자 이메일을 입력해주세요");
                if (!this.inquiry.content) throw new Error("내용을 입력해주세요");
                if (!this.inquiry.file) throw new Error("첨부파일을 선택해주세요");
                if (!this.agree) throw new Error("개인정보 수집ㆍ이용에 동의해주세요");
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
    watch: {
        email1() {
            this.$set(this.inquiry.writer, "email", this.email1 && this.email2 ? `${this.email1}@${this.email2}` : null);
        },
        email2() {
            this.$set(this.inquiry.writer, "email", this.email1 && this.email2 ? `${this.email1}@${this.email2}` : null);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>