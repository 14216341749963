<template>
    <my-page tit="회원정보 수정">
        <myinfo-password v-if="!confirm" @confirm="confirms" />

        <div v-else>
            <div class="mb-8 mb-lg-20 text-right">
                <p class="page-text page-text--sm grey-6--text line-height-1"><span class="primary--text">*</span> 필수입력 항목입니다.</p>
            </div>

            <div class="form--primary">
                <form-row tit="아이디" pointer>
                    <p class="font-size-14 grey-6--text">
                        {{ me.username }}
                    </p>
                </form-row>
                <form-row tit="비밀번호" pointer>
                    <v-text-field v-model="me.password" placeholder="비밀번호를 입력하세요." type="password" name="password" :rules="[rules.password]" persistent-hint outlined />
                </form-row>
                <form-row tit="비밀번호 확인" pointer>
                    <v-text-field v-model="me.password2" placeholder="비밀번호를 입력하세요." type="password" name="password" :rules="[rules.passwordConfirm]" persistent-hint outlined />
                </form-row>
                <form-row tit="이름" pointer>
                    <p class="font-size-14 grey-6--text">
                        {{ me.name }}
                    </p>
                </form-row>
                <form-row tit="닉네임" pointer>
                    <p class="font-size-14 grey-6--text">
                        {{ me.nickname }}
                    </p>
                </form-row>
                <form-row tit="연락처" pointer>
                    <p class="font-size-14 grey-6--text">{{ me.phone1 }} - {{ me.phone2 }} - {{ me.phone3 }}</p>
                </form-row>
                <form-row tit="이메일" pointer>
                    <v-row class="row--x-small" align="center">
                        <v-col cols="12" md="6">
                            <v-text-field v-model="me.email1" placeholder="이메일을 입력하세요." outlined hide-details />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-combobox v-model="me.email2" :items="emailItems" prefix="@" outlined hide-details />
                        </v-col>
                    </v-row>
                </form-row>
                <form-row tit="성별" pointer>
                    <v-radio-group v-model="me.sex" row hide-details>
                        <v-radio label="남성" value="남성" />
                        <v-radio label="여성" value="여성" />
                    </v-radio-group>
                </form-row>
                <form-row tit="생년월일" pointer>
                    <v-row class="row--x-small">
                        <v-col cols="4">
                            <v-text-field v-model="me.bornAt1" placeholder="yyyy" outlined hide-details maxLength="4" />
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="me.bornAt2" placeholder="mm" outlined hide-details maxLength="2" />
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="me.bornAt3" placeholder="dd" outlined hide-details maxLength="2" />
                        </v-col>
                    </v-row>
                </form-row>
                <form-row tit="주소" pointer>
                    <v-select v-model="me.address1" :items="items" outlined hide-details />
                </form-row>
            </div>

            <div class="v-btn--group">
                <v-btn outlined color="grey-b3" class="min-w-120px min-w-lg-160px" @click="withdraw">
                    <span class="grey-6--text">탈퇴하기</span>
                </v-btn>
                <v-btn color="primary" class="min-w-120px min-w-lg-160px" @click="save">
                    저장하기
                </v-btn>
            </div>
        </div>
    </my-page>
</template>

<script>
// import CryptoAES from "@/plugins/crypto-aes";
// import SubVisual from "@/components/client/sub/sub-visual.vue";
// import MyinfoRow from "@/components/client/mypage/myinfo-row.vue";
// import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";
// import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
// import MypageNavigation from "@/components/client/mypage-navigation.vue";

import MyPage from "@/pages/client/templates/MyPage.vue";
import MyinfoPassword from "@/components/client/mypage/myinfo-password.vue";
import FormRow from "@/components/dumb/form-row.vue";
import CryptoAES from "@/plugins/crypto-aes";

import api from "@/api";

export default {
    components: {
        MyPage,
        MyinfoPassword,
        FormRow,
    },
    props: {
        tab: { type: String, default: "view" },
    },
    data() {
        return {
            me: null,
            categories: [],

            confirm: false,

            window,
            emailItems: ["gogle.com", "naver.com", "nate.com"],

            items: [
                { text: "주소를 입력해주세요", value: null },
                { text: "서울특별시", value: "서울특별시" },
                { text: "부산광역시", value: "부산광역시" },
                { text: "인천광역시", value: "인천광역시" },
                { text: "대구광역시", value: "대구광역시" },
                { text: "광주광역시", value: "광주광역시" },
                { text: "대전광역시", value: "대전광역시" },
                { text: "울산광역시", value: "울산광역시" },
                { text: "세종특별자치시", value: "세종특별자치시" },
                { text: "경기도", value: "경기도" },
                { text: "강원도", value: "강원도" },
                { text: "충청북도", value: "충청북도" },
                { text: "충청남도", value: "충청남도" },
                { text: "경상북도", value: "경상북도" },
                { text: "경상남도", value: "경상남도" },
                { text: "전라북도", value: "전라북도" },
                { text: "전라남도", value: "전라남도" },
                { text: "제주특별자치도", value: "제주특별자치도" },
            ],

            rules: {
                username: (value) => {
                    const pattern = /^[a-zA-Z0-9]{3,12}$/;
                    return pattern.test(value) || "3~12 영문·숫자 조합";
                },
                password: (value) => {
                    const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
                    return pattern.test(value) || "영문·숫자·특수문자 조합 8~20자리";
                },
                passwordConfirm: (value) => {
                    return value === this.me.password || "동일한 비밀번호를 입력해주세요";
                },
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (!this.accessToken) {
                    this.$router.push({
                        path: "/login",
                        params: this.$router.params,
                    });
                    return;
                }

                var { user } = await api.v1.me.get();
                var [phone1, phone2, phone3] = (user.phone || "").phoneNumberFormat().split("-");
                var [email1, email2] = (user.email || "").split("@");
                var [bornAt1, bornAt2, bornAt3] = (user.bornAt || "").split("-");

                this.me = {
                    ...user,
                    phone1,
                    phone2,
                    phone3,
                    email1,
                    email2,
                    bornAt1,
                    bornAt2,
                    bornAt3,
                };
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        validate() {
            try {
                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                await api.v1.me.put({
                    ...this.me,
                    password: this.me.password ? CryptoAES.encrypt(this.me.password) : null,
                    password2: null,
                });

                alert("수정되었습니다");

                this.init();
                this.goTop();
            }
        },

        async withdraw() {
            await api.v1.me.withdraw();
            alert("탈퇴되었습니다.");
            this.$store.dispatch("logout").then(() => {
                this.$router.push("/");
            });
        },

        goTop() {
            window.scrollTo({ top: 400, behavior: "smooth" });
        },
        confirms() {
            this.confirm = true;
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        phone() {
            return this.phone1 && this.phone2 && this.phone3 ? [this.phone1, this.phone2, this.phone3].join("-") : undefined;
        },
        payload() {
            return this.$store.state.payload || {};
        },
        scope() {
            return this.payload.scope || [];
        },
    },
    watch: {
        "me.phone1"() {
            var { phone1, phone2, phone3 } = this.me;
            this.me.phone = [phone1, phone2, phone3].join("-");
        },
        "me.phone2"() {
            var { phone1, phone2, phone3 } = this.me;
            this.me.phone = [phone1, phone2, phone3].join("-");
        },
        "me.phone3"() {
            var { phone1, phone2, phone3 } = this.me;
            this.me.phone = [phone1, phone2, phone3].join("-");
        },
        "me.email1"() {
            var { email1, email2 } = this.me;
            this.me.email = [email1, email2].join("@");
        },
        "me.email2"() {
            var { email1, email2 } = this.me;
            this.me.email = [email1, email2].join("@");
        },
        "me.bornAt1"() {
            var { bornAt1, bornAt2, bornAt3 } = this.me;
            this.me.bornAt = [bornAt1, bornAt2, bornAt3].join("-");
        },
        "me.bornAt2"() {
            var { bornAt1, bornAt2, bornAt3 } = this.me;
            this.me.bornAt = [bornAt1, bornAt2, bornAt3].join("-");
        },
        "me.bornAt3"() {
            var { bornAt1, bornAt2, bornAt3 } = this.me;
            this.me.bornAt = [bornAt1, bornAt2, bornAt3].join("-");
        },
    },
};
</script>
