var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('my-page', {
    attrs: {
      "tit": "작성 글"
    }
  }, [_c('div', {
    staticClass: "mb-8 mb-lg-16"
  }, [_c('v-row', {
    staticClass: "row--x-small search--primary"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100 w-md-120px",
    attrs: {
      "items": _vm.searchKeys,
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--small w-100 w-md-240px",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요."
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-100 w-100 min-w-md-60px",
    attrs: {
      "color": "grey-6"
    }
  }, [_c('span', {
    staticClass: "white--text font-size-14",
    on: {
      "click": function ($event) {
        return _vm.search();
      }
    }
  }, [_vm._v("검색")])])], 1)], 1)], 1), _c('v-tabs', {
    staticClass: "v-tabs--punch mb-20 mb-lg-40",
    attrs: {
      "color": "grey-9",
      "hide-slider": ""
    }
  }, [_c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.input('community');
      }
    }
  }, [_vm._v("전체")]), _vm._l(_vm.categories, function (category, index) {
    return _c('v-tab', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.input(category.code);
        }
      }
    }, [_vm._v(_vm._s(category.name))]);
  })], 2), _c('table', {
    staticClass: "board-list board-list--main"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v("카테고리")]), _c('th', {
    staticStyle: {
      "width": "62%"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "11%"
    }
  }, [_vm._v("작성일자")]), _c('th', {
    staticStyle: {
      "width": "11%"
    }
  }, [_vm._v("조회수")]), _c('th', {
    staticStyle: {
      "width": "11%"
    }
  }, [_vm._v("댓글수")])])]), _c('tbody', _vm._l(_vm.boards, function (board) {
    var _board$category;
    return _c('tr', {
      key: board._id,
      on: {
        "click": function ($event) {
          return _vm.move(board._id);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_c('v-chip', {
      attrs: {
        "color": "primary-50",
        "text-color": "primary"
      }
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : (_board$category = board.category) === null || _board$category === void 0 ? void 0 : _board$category.name) + " ")])], 1), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "board-list__tit"
    }, [_c('p', {
      staticClass: "grey-6--text page-text ellip"
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subject) + " ")])])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('span', {
      staticClass: "page-text page-text--sm"
    }, [_vm._v(_vm._s(_vm.$dayjs(board === null || board === void 0 ? void 0 : board.createdAt).format("YYYY.MM.DD")))])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "d-flex align-center",
      class: {
        'justify-end': _vm.justifyEnd,
        'justify-center': !_vm.justifyEnd
      }
    }, [_c('i', {
      staticClass: "icon icon-view mr-4 mr-lg-8"
    }), _c('p', {
      staticClass: "page-text--sm grey-9--text"
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.viewCount) + " ")])])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('board-count-comment', {
      attrs: {
        "commentCount": board.commentCount
      }
    })], 1)]);
  }), 0)]), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }