var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('my-page', [_c('h3', {
    staticClass: "font-size-20 font-size-lg-28 mb-8 mb-lg-16"
  }, [_vm._v(" 나의 검진 및 테스트 결과 ")]), _vm.filter.type !== 'checkup' ? _c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('v-row', {
    staticClass: "row--small ma-lg-n8"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "pa-lg-8",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text grey-6--text"
  }, [_vm._v(" 카테고리 선택 ")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100px",
    attrs: {
      "items": _vm.categories,
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.filter.code,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "code", $$v);
      },
      expression: "filter.code"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "addiction-rows"
  }, _vm._l(_vm.logs, function (log) {
    var _log$addiction, _log$addiction2;
    return _c('div', {
      key: log._id,
      staticClass: "addiction-row"
    }, [_c('v-row', {
      staticClass: "row--large",
      attrs: {
        "align-md": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "4",
        "lg": "auto"
      }
    }, [_c('div', {
      staticClass: "w-100 w-lg-320px"
    }, [_c('router-link', {
      attrs: {
        "to": `/content/test/result/${log === null || log === void 0 ? void 0 : log._id}`
      }
    }, [_c('div', {
      staticClass: "board-item"
    }, [_c('v-card', {
      staticClass: "mb-16",
      attrs: {
        "rounded": ""
      }
    }, [_c('div', {
      staticClass: "thumb"
    }, [_c('div', {
      staticClass: "thumb__inner",
      style: `background-image: url(${log === null || log === void 0 ? void 0 : (_log$addiction = log.addiction) === null || _log$addiction === void 0 ? void 0 : _log$addiction.thumb})`
    })])]), _c('div', {
      staticClass: "board-item__tit px-xl-8 ellip"
    }, [_c('v-chip', {
      staticClass: "mr-4 mr-lg-8",
      attrs: {
        "color": "primary-50",
        "text-color": "primary"
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.displayCategory(log === null || log === void 0 ? void 0 : log.code)))])]), _c('span', {
      staticClass: "tit tit--sm font-weight-medium"
    }, [_vm._v(" " + _vm._s(log === null || log === void 0 ? void 0 : (_log$addiction2 = log.addiction) === null || _log$addiction2 === void 0 ? void 0 : _log$addiction2.subject) + " ")])], 1)], 1)])], 1)]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "8",
        "lg": ""
      }
    }, [_c('div', {
      staticClass: "pb-md-40 pb-lg-44"
    }, [_c('p', {
      staticClass: "page-text grey-6--text mb-4 mb-lg-8"
    }, [_vm._v(_vm._s(_vm.$dayjs(log === null || log === void 0 ? void 0 : log.createdAt).format("YYYY년 MM월 DD일")) + " 진행한 " + _vm._s(_vm.displayCategory(log === null || log === void 0 ? void 0 : log.code)) + " 테스트 결과는...")]), _c('h4', {
      staticClass: "tit tit--sm mb-16 mb-lg-28"
    }, [_c('span', {
      staticClass: "countup"
    }, [_vm._v(_vm._s(log === null || log === void 0 ? void 0 : log.totalScore))]), _vm._v("점 ")]), _c('div', {
      staticClass: "v-progress-linear--primary-group"
    }, _vm._l(log === null || log === void 0 ? void 0 : log.scoreGroups, function (scoreGroup, index) {
      return _c('div', {
        key: index,
        staticClass: "v-progress-linear--primary-wrap"
      }, [_c('v-row', {
        attrs: {
          "align": "center"
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_c('span', {
        staticClass: "chip--score"
      }, [_vm._v(_vm._s(scoreGroup === null || scoreGroup === void 0 ? void 0 : scoreGroup.group))])]), _c('v-col', [_c('v-progress-linear', {
        staticClass: "v-progress-linear--primary",
        attrs: {
          "value": (scoreGroup === null || scoreGroup === void 0 ? void 0 : scoreGroup.score) * 100 / (scoreGroup === null || scoreGroup === void 0 ? void 0 : scoreGroup.maxScore),
          "buffer-value": "100"
        }
      })], 1), _c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_c('div', {
        staticClass: "pl-lg-14"
      }, [_c('p', {
        staticClass: "min-w-lg-40px page-text grey-6--text text-right",
        staticStyle: {
          "min-width": "35px"
        }
      }, [_c('span', {
        staticClass: "countup"
      }, [_vm._v(_vm._s(scoreGroup === null || scoreGroup === void 0 ? void 0 : scoreGroup.score))]), _vm._v("점 ")])])])], 1)], 1);
    }), 0)])])], 1)], 1);
  }), 0), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount
    },
    on: {
      "input": function ($event) {
        return _vm.search(false);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }