<template>
    <client-page>
        <section class="section section--last">
            <v-container>
                <div class="mb-20 mb-lg-40 text-center">
                    <h2 class="tit tit--lg line-height-1 mb-12 mb-lg-20">
                        유경험자 인증 신청
                    </h2>
                    <p class="tit tit--sm primary--text">
                        첨부파일의 제출 필요 서류는 명함, 증명서 등등이 있습니다.
                    </p>
                </div>

                <div class="mb-8 mb-lg-20 text-right">
                    <p class="page-text page-text--sm grey-6--text line-height-1"><span class="primary--text">*</span> 필수입력 항목입니다.</p>
                </div>
                <div class="form--primary">
                    <form-row tit="카테고리" pointer>
                        <v-select v-model="levelup.category" :items="items" placeholder="--선택--" outlined hide-details />
                    </form-row>
                    <form-row tit="내용" pointer>
                        <v-textarea v-model="levelup.content" placeholder="내용을 입력하세요." rows="8" outlined hide-details />
                    </form-row>
                    <form-row tit="파일첨부" pointer>
                        <v-file-input v-model="levelup.file" placeholder="파일을 첨부하세요." outlined hide-details />
                    </form-row>
                </div>
                <div class="form__agreement">
                    <v-checkbox v-model="agree" label="개인정보 수집ㆍ이용 동의" hide-details />
                    <button onclick="window.open('/privacy-policy', '', 'width=483,height=700');" class="form__agreement__btn">전문보기</button>
                </div>

                <div class="v-btn--group">
                    <v-btn outlined color="primary" class="min-w-120px min-w-lg-160px">
                        리스트
                    </v-btn>
                    <v-btn color="primary" class="min-w-120px min-w-lg-160px" @click="save">
                        신청하기
                    </v-btn>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import FormRow from "@/components/dumb/form-row.vue";

export default {
    components: {
        ClientPage,
        FormRow,
    },

    data() {
        return {
            levelup: {
                code: "experience",
                category: null,
                content: null,
                file: null,
            },
            items: [
                { text: "약물", value: "drug" },
                { text: "성", value: "sex" },
                { text: "도박", value: "gambling" },
                { text: "인터넷", value: "internet" },
                { text: "알코올", value: "alcohol" },
            ],
            agree: false,
        };
    },
    methods: {
        validate() {
            try {
                if (!this.levelup.category) throw new Error("카테고리를 선택해주세요");
                if (!this.levelup.content) throw new Error("내용을 입력해주세요");
                if (!this.levelup.file) throw new Error("파일을 선택해주세요");
                if (!this.agree) throw new Error("개인정보 수집ㆍ이용에 동의해주세요");
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (this.validate()) {
                let { levelup } = await api.v1.me.levelup.post({ ...this.levelup });
                if (this.levelup.file) {
                    await api.v1.me.levelup.file.post(levelup._id, this.levelup.file);
                }
                alert("유경험자 인증 신청되었습니다");
                this.$router.push("/mypage/addiction");
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
