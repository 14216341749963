<template>
    <div class="mypage-navigation-wrap">
        <v-card color="grey-fa" class="mb-20 mb-xl-10">
            <div class="pa-20">
                <v-row align="center" class="row--x-small">
                    <v-col>
                        <h4 class="tit tit--sm line-height-1">{{ user.name }}님</h4>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn to="/mypage/myinfo" text small color="grey-6" class="v-btn--text-fit">
                            회원정보 수정
                            <v-icon size="14" class="ml-4">mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-6 my-lg-12" />
                <div class="mb-4 mb-lg-10">
                    <v-row class="row--x-small" align="center">
                        <v-col cols="auto">
                            <p class="page-text grey-6--text">
                                회원등급
                            </p>
                        </v-col>
                        <v-spacer class="d-none d-xl-block" />
                        <v-col cols="auto" class="ml-2 ml-lg-6 ml-xl-0">
                            <v-chip color="primary-50" class="v-chip--medium">
                                <span class="primary--text">
                                    {{ user?.level?.name }}
                                </span>
                            </v-chip>
                        </v-col>
                    </v-row>
                </div>
                <v-row class="row--x-small">
                    <v-col>
                        <v-btn to="/mypage/expert" small block color="primary">
                            전문가 인증
                        </v-btn>
                    </v-col>
                    <!-- <v-col cols="6" md="auto" xl="6">
                        <v-btn to="/mypage/experience" small block color="primary">
                            유경험자 인증
                        </v-btn>
                    </v-col> -->
                </v-row>
            </div>
        </v-card>
        <ul class="mypage-navigation">
            <li class="mypage-navigation__list">
                <router-link to="/mypage/community" active-class="on" class="mypage-navigation__link">
                    <i class="icon icon-column"></i>
                    <span>작성 글</span> <!-- 구 커뮤니티 -->
                </router-link>
            </li>
            <!-- <li v-if="user.levelCode !== 'normal'" class="mypage-navigation__list">
                <router-link to="/mypage/writing" active-class="on" class="mypage-navigation__link">
                    <i class="icon icon-column"></i>
                    <span>작성 글</span>
                </router-link>
            </li> -->
            <li class="mypage-navigation__list">
                <router-link to="/mypage/bookmark" active-class="on" class="mypage-navigation__link">
                    <i class="icon icon-bookmark"></i>
                    <span>북마크한 글</span>
                </router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/addiction" active-class="on" class="mypage-navigation__link">
                    <i class="icon icon-addiction"></i>
                    <span>테스트 결과</span>
                </router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/survey" active-class="on" class="mypage-navigation__link">
                    <i class="icon icon-survey"></i>
                    <span>설문조사 결과</span>
                </router-link>
            </li>
            <li class="mypage-navigation__list">
                <router-link to="/mypage/inquiry" active-class="on" class="mypage-navigation__link">
                    <i class="icon icon-inquiry"></i>
                    <span>문의 내역</span>
                </router-link>
            </li>
            <!-- <li v-if="user.levelCode !== 'normal'" class="mypage-navigation__list">
                <router-link to="/mypage/column" active-class="on" class="mypage-navigation__link">
                    <i class="icon icon-column"></i>
                    <span>나의 칼럼</span>
                </router-link>
            </li> -->
        </ul>
    </div>
</template>
<script>
import api from "@/api";
export default {
    data() {
        return {
            //  화살표 아이콘
            icon: '<svg x="0px" y="0px" viewBox="0 0 9.7 6.4" style="fill:none;stroke:#333333;stroke-width:2;stroke-linecap:round;"><path d="M1,1.4l3.8,4l3.9-4"/></svg>',
            user: {},
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            if(!this.logon){
            alert("로그인 이후에 사용가능합니다");
            this.$router.push(`/login`);
            return;
        }
            let { user } = await api.v1.me.get();
            this.user = user;
        },
        accordionToggle(event) {
            var button = event.currentTarget,
                contents = button.nextSibling;
            var height = 0;

            if (button.getAttribute("data-toggle") === "closed") {
                contents.style.maxHeight = "none";
                height = contents.clientHeight;
                contents.style.maxHeight = "0";
                setTimeout(function() {
                    contents.style.maxHeight = height + "px";
                }, 1);
                button.setAttribute("data-toggle", "opened");
                contents.ontransitionend = () => {
                    contents.removeAttribute("style");
                };
            } else {
                height = contents.clientHeight;
                contents.style.maxHeight = height + "px";
                setTimeout(function() {
                    contents.style.maxHeight = "0px";
                }, 1);
                button.setAttribute("data-toggle", "closed");
                contents.ontransitionend = () => {
                    contents.removeAttribute("style");
                };
            }
        },
    },
    computed: {
        payload() {
            return this.$store.state.payload || {};
        },
        levleCode() {
            return this.payload.levleCode;
        },
        scope() {
            return this.payload.scope || [];
        },
        accessToken(){
            return this.$store.state.accessToken;
        },
        logon(){ 
            return !!this.accessToken 
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.mypage-navigation-wrap {
    width: 100%;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .mypage-navigation-wrap {
        width: 250px;
    }
}

.mypage-navigation {
    list-style: none;
    .on {
        position: relative;
        color: var(--v-primary-base);
        background-color: #fafafa;
    }
    &-container {
        width: 100%;
    }
    &__list:not(:last-child) {
        margin-bottom: 10px;
    }
    &__link {
        border-radius: 6px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 8px 10px;
        font-size: 1.6rem;
        color: #999;
        font-weight: 500;
        cursor: pointer;
        user-select: none;
        i {
            margin-right: 10px;
        }
    }
    &__link[data-toggle] {
        user-select: none;
    }
    &__link[data-toggle] .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 11px;
        height: 20px;
    }
    &__link[data-toggle] .arrow::v-deep svg {
        display: block;
        width: 11px;
        height: 7px;
        pointer-events: none;
    }
    &__link[data-toggle="opened"] {
        border-bottom-style: dashed;
    }
    &__link[data-toggle="opened"] .arrow {
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
    }
    &__link[data-toggle="closed"] + &__sub {
        max-height: 0;
    }
    &__sub {
        overflow: hidden;
        transition: all ease 0.2s;
    }
    &__level {
        list-style: none;
        &--1 {
            padding: 15px 0 5px;
            border-bottom: 1px solid #dddddd;
        }
        &-link {
            border-radius: 0;
            display: block;
            padding: 0 10px;
            height: 30px;
            line-height: 1;
            font-size: 18px;
            color: #555555;
            font-weight: 300;
            cursor: pointer;
            user-select: none;
        }
        &-link[data-toggle="opened"] {
            color: var(--v-primary-base);
            font-weight: 700;
        }
        &-link[data-toggle="opened"] + .mypage-navigation__level {
            display: block;
        }
    }
}
@media (min-width: 1200px) {
    .mypage-navigation__link:hover {
        background-color: #fafafa;
    }
}
@media (max-width: 1199px) {
    .mypage-navigation {
        display: flex;
        scrollbar-width: none;
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;
        white-space: nowrap;
        border-bottom: 1px solid var(--border-color);
        .on {
            border-color: var(--v-primary-base);
            background-color: transparent;
        }
        .on::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: var(--v-primary-base);
        }
        &::-webkit-scrollbar {
            display: none;
        }
        &__list {
            flex: 1 1 auto;
        }
        &__list:not(:last-child) {
            margin-bottom: 0;
        }
        &__link {
            width: auto;
            display: block;
            font-size: 1.4rem;
            text-align: center;
            padding: 12px;
            i {
                display: none;
            }
        }
        &__list-link {
            margin-right: 18px;
            padding: 18px 0 0;
            height: 50px;
            font-size: 14px;
            color: #777777;
            font-weight: 400;
            border-bottom: none;
        }
        &__list-link span {
            display: block;
            padding-bottom: 8px;
            border-bottom: 1px solid transparent;
        }
        &__list-link[data-toggle] .arrow {
            display: none;
        }
        &__list-link[data-toggle="opened"] {
            border-bottom: none;
            color: #333333;
            font-weight: 500;
        }
        &__list-link[data-toggle="opened"] span {
            border-bottom-color: #333333;
        }
        &__level {
            &--1 {
                display: none;
                align-items: center;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50px;
            }
            &--1.active {
                display: flex;
            }
            &-link {
                margin-right: 18px;
                padding: 0;
                font-size: 14px;
            }
        }
    }
    .mypage-navigation__tit {
        font-size: 2.4rem;
        margin-bottom: 0;
    }
}
</style>
