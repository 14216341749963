<template>
    <div class="addiction-question-view">
        <div v-html="question.content" class="w-100 mb-20 mb-lg-40"></div>
        <div class="addiction-question-view__head mb-20 mb-lg-40">
            <v-chip large color="grey-f5" class="grey-1--test"><span :style="'color :'+themeColor">{{ index }}</span>/{{ questions.length }}</v-chip>
            <h3 class="tit">{{ question.question }}</h3>
        </div>
        <ul class="addiction-question-view__questions">
            <li v-for="(answer, index) in question.answers" :key="index" class="w-100">
                <v-btn height="auto" class="addiction-question-view__question-btn py-14 py-lg-16" block outlined large :color="themeColor" @click="$emit('next', answer)">
                    <span class="font-weight-regular">{{ answer.answer }}</span>
                </v-btn>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        questions: { type: Array },
        question: { type: Object },
        index: { type: Number },
        themeColor: { type: String },
    },
};
</script>

<style lang="scss" scoped>
.addiction-question-view__head{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .v-chip{
        margin-bottom: 12px;
    }
}
.addiction-question-view__questions{
    >li:not(:last-child){
        margin-bottom: 4px;
    }
}

.addiction-question-view__question-btn.v-btn::before{
    display: none;
}
.addiction-question-view__question-btn:not(:hover).v-btn{
    border-color: #ddd !important;
    color: #666 !important;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .addiction-question-view__head{
        position: relative;
        flex-direction: row;
        width: 100%;
        min-height: 48px;
        padding: 0 150px;
        .v-chip{
            margin-bottom: 0;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .addiction-question-view__questions{
        >li:not(:last-child){
            margin-bottom: 8px;
        }
    }
}
@media (min-width:1200px){
}

</style>
