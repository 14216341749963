var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section--first section--last"
  }, [_c('v-container', [_c('h2', {
    staticClass: "tit tit--lg line-height-1 text-center mb-20 mb-lg-40"
  }, [_vm._v(" 신고가이드 ")]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.terms.content)
    }
  })])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }