<template>
    <my-page tit="북마크한 글">
        <div class="mb-8 mb-lg-16">
            <v-row class="row--x-small search--primary">
                <v-spacer></v-spacer>
                <v-col cols="12" md="auto">
                    <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-120px" outlined hide-details></v-select>
                </v-col>
                <v-col cols="9" md="auto">
                    <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요."/>
                </v-col>
                <v-col cols="3" md="auto">
                    <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6" @click="search()">
                        <span class="white--text font-size-14">검색</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-tabs color="grey-9" hide-slider class="v-tabs--punch mb-20 mb-lg-40">
            <v-tab @click="setCode('community')">커뮤니티</v-tab>
            <v-tab @click="setCode('content')">영상</v-tab>
            <v-tab @click="setCode('column')">칼럼</v-tab>
        </v-tabs>
        <table class="board-list board-list--main">
            <thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:100px;">카테고리</th>
                    <th style="width:62%;">제목</th>
                    <th style="width:11%;">작성일자</th>
                    <th style="width:11%;">조회수</th>
                    <th style="width:11%;">댓글수</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="bookmark in bookmarks" :key="bookmark._id" @click="move(bookmark._board)">
                    <td align="center" class="board-list__txt board-list__txt--first">
                        <v-chip color="primary-50" text-color="primary">
                            {{ bookmark?.board?.category?.name }}
                        </v-chip>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="board-list__tit">
                            <p class="grey-6--text page-text ellip">
                                {{ bookmark?.board?.subject }}
                            </p>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt">
                        <span class="page-text page-text--sm">
                            {{ $dayjs(bookmark?.board?.createdAt).format("YYYY.MM.DD") }}
                        </span>
                    </td>
                    <td align="center" class="board-list__txt">
                        <board-count-view :viewCount="bookmark?.board?.viewCount" />
                    </td>
                    <td align="center" class="board-list__txt">
                        <board-count-comment :commentCount="bookmark?.board?.commentCount"/>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search()"/>
        </div>
    </my-page>
</template>

<script>
import api from '@/api';
import MyPage from "@/pages/client/templates/MyPage.vue";
import SearchPrimary from "@/components/client/board/search--primary.vue";
import BoardCountView from "@/components/client/board/board-count--view.vue";
import BoardCountComment from "@/components/client/board/board-count--comment.vue";

export default {
    components: {
        MyPage,
        SearchPrimary,
        BoardCountView,
        BoardCountComment,
    },
    props: {
    },
    async monuted() {
    },
    data() {
        return {
            bookmarks: [],

            page: 1,
            pageCount: 0,
            limit: 10,

            filter: {
                code: "community",
                searchKey: "board.subjectcontent",
                searchValue: null,
            },

            searchKeys: [
                { text: "제목+내용", value: "board.subjectcontent" },
                { text: "제목", value: "board.subject" },
            ],
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            await this.search()
        },
        async search(routable = false){
            if(routable){
                this.router.push({
                    query: { ...this.filter, page: this.page }
                });
            }
        
            var { summary, bookmarks } = await api.v1.me.boards.bookmarks.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });
        
            this.bookmarks = bookmarks;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        async setCode(code) {
            this.filter.code = code;
            await this.init();
        },
        move(_board) {
            if (this.filter.code === "community") this.$router.push(`/community/board/${_board}`);
            if (this.filter.code === "content") this.$router.push(`/content/solution/${_board}`);
            if (this.filter.code === "column") this.$router.push(`/content/column/${_board}`)
        }
    }
};
</script>
