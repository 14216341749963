var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section--first section--last"
  }, [_c('v-container', [_c('h2', {
    staticClass: "tit tit--lg line-height-1 text-center mb-20 mb-lg-40"
  }, [_vm._v(" 문의사항 ")]), _c('div', {
    staticClass: "mb-8 mb-lg-20 text-right"
  }, [_c('p', {
    staticClass: "page-text page-text--sm grey-6--text line-height-1"
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("*")]), _vm._v(" 필수입력 항목입니다. ")])]), _c('div', {
    staticClass: "form--primary"
  }, [_c('form-row', {
    attrs: {
      "tit": "제목",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "제목을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.inquiry.subject,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry, "subject", $$v);
      },
      expression: "inquiry.subject"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "작성자",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "작성자를 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.inquiry.writer.name,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry.writer, "name", $$v);
      },
      expression: "inquiry.writer.name"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "연락처",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "전화번호를 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.inquiry.writer.phone,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry.writer, "phone", $$v);
      },
      expression: "inquiry.writer.phone"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "이메일",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "이메일을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.email1,
      callback: function ($$v) {
        _vm.email1 = $$v;
      },
      expression: "email1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-combobox', {
    attrs: {
      "prefix": "@",
      "items": _vm.emailItems,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.email2,
      callback: function ($$v) {
        _vm.email2 = $$v;
      },
      expression: "email2"
    }
  })], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "내용",
      "pointer": ""
    }
  }, [_c('v-textarea', {
    attrs: {
      "placeholder": "내용을 입력하세요.",
      "rows": "8",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.inquiry.content,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry, "content", $$v);
      },
      expression: "inquiry.content"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "파일첨부",
      "pointer": ""
    }
  }, [_c('v-file-input', {
    attrs: {
      "placeholder": "파일을 첨부하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.inquiry.file,
      callback: function ($$v) {
        _vm.$set(_vm.inquiry, "file", $$v);
      },
      expression: "inquiry.file"
    }
  })], 1)], 1), _c('div', {
    staticClass: "form__agreement"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "개인정보 수집ㆍ이용 동의",
      "hide-details": ""
    },
    model: {
      value: _vm.agree,
      callback: function ($$v) {
        _vm.agree = $$v;
      },
      expression: "agree"
    }
  }), _c('button', {
    staticClass: "form__agreement__btn",
    attrs: {
      "onclick": "window.open('/privacy-policy', '', 'width=483,height=700');"
    }
  }, [_vm._v("전문보기")])], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-120px min-w-lg-160px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 작성하기 ")])], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }