var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "addiction-question-view"
  }, [_c('div', {
    staticClass: "w-100 mb-20 mb-lg-40",
    domProps: {
      "innerHTML": _vm._s(_vm.question.content)
    }
  }), _c('div', {
    staticClass: "addiction-question-view__head mb-20 mb-lg-40"
  }, [_c('v-chip', {
    staticClass: "grey-1--test",
    attrs: {
      "large": "",
      "color": "grey-f5"
    }
  }, [_c('span', {
    style: 'color :' + _vm.themeColor
  }, [_vm._v(_vm._s(_vm.index))]), _vm._v("/" + _vm._s(_vm.questions.length))]), _c('h3', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.question.question))])], 1), _c('ul', {
    staticClass: "addiction-question-view__questions"
  }, _vm._l(_vm.question.answers, function (answer, index) {
    return _c('li', {
      key: index,
      staticClass: "w-100"
    }, [_c('v-btn', {
      staticClass: "addiction-question-view__question-btn py-14 py-lg-16",
      attrs: {
        "height": "auto",
        "block": "",
        "outlined": "",
        "large": "",
        "color": _vm.themeColor
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('next', answer);
        }
      }
    }, [_c('span', {
      staticClass: "font-weight-regular"
    }, [_vm._v(_vm._s(answer.answer))])])], 1);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }