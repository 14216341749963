var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('table', {
    staticClass: "board-list board-list--main mb-20 mb-lg-40"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.inquirys, function (inquiry) {
    return _c('tr', {
      key: inquiry._id,
      on: {
        "click": function ($event) {
          return _vm.$router.push(`/mypage/inquiry/${inquiry._id}`);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.limit + (+_vm.inquirys.indexOf(inquiry) + 1)) + " ")]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('p', {
      staticClass: "grey-6--text page-text ellip"
    }, [_vm._v(" " + _vm._s(inquiry.subject) + " ")])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [inquiry.reply.content ? _c('span', {
      staticClass: "page-text page-text--sm primary-text"
    }, [_vm._v("답변완료")]) : _c('span', {
      staticClass: "page-text page-text--sm"
    }, [_vm._v("대기중")])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('span', {
      staticClass: "page-text page-text--sm"
    }, [_vm._v(_vm._s(_vm.$dayjs(inquiry.createdAt).format("YYYY.MM.DD")))])])]);
  }), 0)]), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-lg-40px",
    attrs: {
      "outlined": "",
      "color": "primary",
      "to": "/mypage/inquiry/create"
    }
  }, [_vm._v("문의하기")])], 1)], 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "70px"
    }
  }, [_vm._v("No.")]), _c('th', [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "110px"
    }
  }, [_vm._v("답변여부")]), _c('th', {
    staticStyle: {
      "width": "110px"
    }
  }, [_vm._v("날짜")])])]);

}]

export { render, staticRenderFns }