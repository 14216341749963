<template>
    <my-page>
        <h3 class="font-size-20 font-size-lg-28 mb-8 mb-lg-16">
            나의 검진 및 테스트 결과
        </h3>
        <div v-if="filter.type !== 'checkup'" class="mb-20 mb-lg-40">
            <v-row class="row--small ma-lg-n8">
                <v-spacer />
                <v-col cols="auto" class="pa-lg-8">
                    <v-row class="row--x-small" align="center">
                        <v-col cols="auto">
                            <p class="page-text grey-6--text">
                                카테고리 선택
                            </p>
                        </v-col>
                        <v-col cols="auto">
                            <v-select v-model="filter.code" :items="categories" item-text="name" item-value="code" class="v-input--small w-100px" outlined hide-details @input="search(true)" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <div class="addiction-rows">
            <!-- S::검진 및 테스트 결과 -->
            <div v-for="log in logs" :key="log._id" class="addiction-row">
                <v-row class="row--large" align-md="center">
                    <v-col cols="12" md="4" lg="auto">
                        <div class="w-100 w-lg-320px">
                            <router-link :to="`/content/test/result/${log?._id}`">
                                <div class="board-item">
                                    <v-card rounded class="mb-16">
                                        <div class="thumb">
                                            <div class="thumb__inner" :style="`background-image: url(${log?.addiction?.thumb})`"></div>
                                        </div>
                                    </v-card>
                                    <div class="board-item__tit px-xl-8 ellip">
                                        <v-chip color="primary-50" text-color="primary" class="mr-4 mr-lg-8">
                                            <span>{{ displayCategory(log?.code) }}</span>
                                        </v-chip>
                                        <span class="tit tit--sm font-weight-medium">
                                            {{ log?.addiction?.subject }}
                                        </span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </v-col>
                    <v-col cols="12" md="8" lg="">
                        <div class="pb-md-40 pb-lg-44">
                            <p class="page-text grey-6--text mb-4 mb-lg-8">{{ $dayjs(log?.createdAt).format("YYYY년 MM월 DD일") }} 진행한 {{ displayCategory(log?.code) }} 테스트 결과는...</p>
                            <h4 class="tit tit--sm mb-16 mb-lg-28">
                                <span class="countup">{{ log?.totalScore }}</span
                                >점
                            </h4>
                            <div class="v-progress-linear--primary-group">
                                <div v-for="(scoreGroup, index) in log?.scoreGroups" :key="index" class="v-progress-linear--primary-wrap">
                                    <v-row align="center">
                                        <v-col cols="auto">
                                            <span class="chip--score">{{ scoreGroup?.group }}</span>
                                        </v-col>
                                        <v-col>
                                            <v-progress-linear class="v-progress-linear--primary" :value="(scoreGroup?.score * 100) / scoreGroup?.maxScore" buffer-value="100" />
                                        </v-col>
                                        <v-col cols="auto">
                                            <div class="pl-lg-14">
                                                <p class="min-w-lg-40px page-text grey-6--text text-right" style="min-width: 35px">
                                                    <span class="countup">{{ scoreGroup?.score }}</span
                                                    >점
                                                </p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <!-- E::검진 및 테스트 결과 -->
        </div>
        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" @input="search(false)"></v-pagination>
        </div>
    </my-page>
</template>

<script>
import api from "@/api";
import MyPage from "@/pages/client/templates/MyPage.vue";
import AddictionItemPrimary from "@/components/client/addiction/addiction-item--primary.vue";

export default {
    components: {
        MyPage,
        AddictionItemPrimary,
    },
    props: {},
    data() {
        return {
            logs: [],

            page: 1,
            limit: 3,
            totalCount: 0,

            filter: {
                type: this.$route.query.type || null,
                code: this.$route.query.code || null,
            },

            categories: [
                { name: "전체", code: null }
            ],
            categoryItems: {}
        };
    },
    async mounted() {
        await this.init();
    },

    methods: {
        async init() {
            let { categories } = await api.v1.addictions.categories.gets({ params: { depth: 1 } })
            this.categories = [ ...this.categories, ...categories ];
            categories.map(el => {
                this.categoryItems[`${el.code}`] = el.name
            })
            await this.search();
            setTimeout(function() {
                // countup
                const animationDuration = 2000;
                const frameDuration = 1000 / 60;
                const totalFrames = Math.round(animationDuration / frameDuration);
                const easeOutQuad = (t) => t * (2 - t);

                const animateCountUp = (el) => {
                    let frame = 0;
                    const countTo = parseInt(el.innerHTML, 10);
                    const counter = setInterval(() => {
                        frame++;
                        const progress = easeOutQuad(frame / totalFrames);
                        const currentCount = Math.round(countTo * progress);

                        if (parseInt(el.innerHTML, 10) !== currentCount) {
                            el.innerHTML = currentCount;
                        }

                        if (frame === totalFrames) {
                            clearInterval(counter);
                        }
                    }, frameDuration);
                };

                const countupEls = document.querySelectorAll(".countup");
                countupEls.forEach(animateCountUp);
            }, 10);
        },
        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
            }

            var { summary, logs } = await api.v1.me.addiction.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.logs = logs;

            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        input(type) {
            this.filter.type = type;
            this.filter.code = null;
            this.page = 1;
            this.$router.push({
                query: { type: this.filter.type },
            });
            this.search();
        },
        displayCategory(code) {
            return this.categoryItems[code];
        },
    },
};
</script>

<style lang="scss" scoped>
.addiction-rows {
    .addiction-row:not(:last-child) {
        margin-bottom: 10px;
    }
}
@media (min-width: 1024px) {
    .v-progress-linear--primary-group > .v-progress-linear--primary-wrap:not(:last-child) {
        margin-bottom: 6px;
    }
    .addiction-rows {
        .addiction-row:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}
</style>
