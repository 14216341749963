var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section section--last"
  }, [_c('v-container', [_c('div', {
    staticClass: "mb-20 mb-lg-40 text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg line-height-1 mb-12 mb-lg-20"
  }, [_vm._v(" 전문가 인증 신청 ")]), _c('p', {
    staticClass: "tit tit--sm primary--text"
  }, [_vm._v(" 첨부파일의 제출 필요 서류는 명함, 증명서 등등이 있습니다. ")])]), _c('div', {
    staticClass: "mb-8 mb-lg-20 text-right"
  }, [_c('p', {
    staticClass: "page-text page-text--sm grey-6--text line-height-1"
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("*")]), _vm._v(" 필수입력 항목입니다.")])]), _c('div', {
    staticClass: "form--primary"
  }, [_c('form-row', {
    attrs: {
      "tit": "카테고리",
      "pointer": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.items,
      "placeholder": "--선택--",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.levelup.category,
      callback: function ($$v) {
        _vm.$set(_vm.levelup, "category", $$v);
      },
      expression: "levelup.category"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "내용",
      "pointer": ""
    }
  }, [_c('v-textarea', {
    attrs: {
      "placeholder": "내용을 입력하세요.",
      "rows": "8",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.levelup.content,
      callback: function ($$v) {
        _vm.$set(_vm.levelup, "content", $$v);
      },
      expression: "levelup.content"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "파일첨부",
      "pointer": ""
    }
  }, [_c('v-file-input', {
    attrs: {
      "placeholder": "파일을 첨부하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.levelup.file,
      callback: function ($$v) {
        _vm.$set(_vm.levelup, "file", $$v);
      },
      expression: "levelup.file"
    }
  })], 1)], 1), _c('div', {
    staticClass: "form__agreement"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "개인정보 수집ㆍ이용 동의",
      "hide-details": ""
    },
    model: {
      value: _vm.agree,
      callback: function ($$v) {
        _vm.agree = $$v;
      },
      expression: "agree"
    }
  }), _c('button', {
    staticClass: "form__agreement__btn",
    attrs: {
      "onclick": "window.open('/privacy-policy', '', 'width=483,height=700');"
    }
  }, [_vm._v("전문보기")])], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-120px min-w-lg-160px",
    attrs: {
      "outlined": "",
      "color": "primary",
      "t": ""
    }
  }, [_vm._v(" 리스트 ")]), _c('v-btn', {
    staticClass: "min-w-120px min-w-lg-160px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 신청하기 ")])], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }