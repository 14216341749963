var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "mb-n12 mb-sm-0"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-12 mb-sm-0",
    attrs: {
      "cols": "12",
      "sm": "4"
    },
    on: {
      "click": function ($event) {
        return _vm.move(_vm.board1);
      }
    }
  }, [_c('board-item-primary', {
    attrs: {
      "board": _vm.board1
    }
  })], 1), _c('v-col', {
    staticClass: "mb-12 mb-sm-0",
    attrs: {
      "cols": "12",
      "sm": "4"
    },
    on: {
      "click": function ($event) {
        return _vm.move(_vm.board2);
      }
    }
  }, [_c('board-item-primary', {
    attrs: {
      "board": _vm.board2
    }
  })], 1), _c('v-col', {
    staticClass: "mb-12 mb-sm-0",
    attrs: {
      "cols": "12",
      "sm": "4"
    },
    on: {
      "click": function ($event) {
        return _vm.move(_vm.board3);
      }
    }
  }, [_c('board-item-primary', {
    attrs: {
      "board": _vm.board3
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(" 추천 콘텐츠 ")])]);

}]

export { render, staticRenderFns }