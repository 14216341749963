<template>
    <div class="board__list">

        <h2 class="tit tit--lg line-height-1 text-center mb-20 mb-lg-40">
            공지사항
        </h2>

        <div>
            <table class="board-list board-list--main">
                <thead class="d-none d-lg-table-header">
                    <tr>
                        <th style="width:70px;">NO.</th>
                        <th style="width:100%;">제목</th>
                        <th style="width:110px;">날짜</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- S::공지게시글 -->
                    <tr v-for="board in fixed" :key="board._id" @click="move(board._id)">
                        <td align="center" class="board-list__txt board-list__txt--first">
                            <i class="icon icon-notice"></i>
                        </td>
                        <td align="center" class="board-list__tit">
                            <div class="board-list__tit">
                                <p class="grey-6--text page-text ellip">
                                    {{ board?.subject }}
                                </p>
                            </div>
                        </td>
                        <td align="center" class="board-list__txt">
                            <span class="font-size-14">{{ $dayjs(board?.createdAt).format("YYYY.MM.DD") }}</span>
                        </td>
                    </tr>
                    <!-- E::공지게시글 -->

                    <!-- S::일반게시글 -->
                    <tr v-for="notification in notifications" :key="notification._id" @click="move(notification._id)">
                        <td align="center" class="board-list__txt board-list__txt--first">
                            <span class="font-size-14">1</span>
                        </td>
                        <td align="center" class="board-list__tit">
                            <div class="board-list__tit">
                                <p class="grey-6--text page-text ellip">
                                    {{ notification?.subject }}
                                </p>
                            </div>
                        </td>
                        <td align="center" class="board-list__txt">
                            <span class="font-size-14">{{ $dayjs(notification?.createdAt).format("YYYY.MM.DD") }}</span>
                        </td>
                    </tr>
                    <!-- E::일반게시글 -->
                </tbody>
            </table>
        </div>

        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search" />
        </div>

    </div>
</template>

<script>
import api from '@/api'

export default {
    components: {
    },

    data() {
        return {
            page: 1,
            pageCount: 0,
            limit: 10,

            filter: { isNotice: false },

            fixed: null,

            notifications: null,
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            await this.search()
        },
        async search(){
            let { notifications: fixed } = await api.v1.center.notifications.gets({
                headers: {
                    limit: 0
                },
                params: { isNotice: true }
            });

            this.fixed = fixed;

            let { summary, notifications } = await api.v1.center.notifications.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.notifications = notifications;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        move(_id) {
            this.$router.push(`/notice/${_id}`)
        }
    }
};
</script>

<style lang="scss" scoped>
.v-menu__content{
    background-color: transparent;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    transform: translate(20px, 2px);
}
</style>