<template>
    <my-page tit="설문조사 결과">
        <div>
            <!-- S: 설문조사 결과 -->
            <v-card v-for="result in results" :key="result._id" class="survey-card pa-16 pa-md-20 border-bottom" @click="$router.push(`/content/survey/result/${result?._id}`)">
                <v-row align="center" justify="center" class="flex-lg-nowrap">
                    <v-col cols="auto" order="1" order-lg="1">
                        <v-card rounded color="transparent" width="258" max-width="100%">
                            <v-img :src="result?.survey?.thumb" :aspect-ratio="258 / 146" class="w-100" />
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="" order="3" order-lg="2" class="survey-details mt-10 mt-lg-0">
                        <v-row class="row--x-small">
                            <v-col cols="auto">
                                <v-chip color="primary-50" text-color="primary">{{ result?.survey?.category }}</v-chip>
                            </v-col>
                            <v-col>
                                <h4 class="tit tit--sm font-weight-medium ellip">
                                    {{ result?.survey?.subject }}
                                </h4>
                            </v-col>
                        </v-row>
                        <p class="page-text grey-6--text mt-8 mt-lg-16">
                            {{ $dayjs(result?.createdAt).format("YYYY.MM.DD") }}
                        </p>
                    </v-col>
                    <v-col cols="auto" order="2" order-lg="3">
                        <v-card elevation="5" rounded color="transparent" width="146" max-width="100%">
                            <!-- <v-img src="/images/test2.jpg" :aspect-ratio="1 / 1" class="w-100" /> -->
                            <p v-html="result?.content"></p>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <!-- E: 설문조사 결과 -->
        </div>
        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="init()"/>
        </div>
    </my-page>
</template>

<script>
import api from "@/api"
import MyPage from "@/pages/client/templates/MyPage.vue";

export default {
    components: {
        MyPage,
    },
    async mounted() {
        await this.init();
    },
    data() {
        return {
            page: 1,
            limit: 4,
            pageCount: 0,

            results: []
        }
    },
    methods: {
        async init() {
            let { summary, results } = await api.v1.me.survey.result.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                }
            })
            console.log(results);
            this.results = results;
            this.pageCount = Math.ceil(summary.totalCount / this.limit) || 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.survey-card::before{
    display: none;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .survey-details{
        max-width: 846px;
    }
}
@media (min-width:1200px){
}

</style>
