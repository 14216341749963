<template>
    <my-page tit="나의 문의사항">
        <inquiry-list />
    </my-page>
</template>

<script>
import MyPage from "@/pages/client/templates/MyPage.vue";
import InquiryList from "@/components/client/mypage/inquiry/list.vue";
import InquiryInput from "@/components/client/mypage/inquiry/input.vue";
import InquiryView from "@/components/client/mypage/inquiry/view.vue";

export default {
    components: {
        MyPage,
        InquiryList,
        InquiryInput,
        InquiryView,
    },
    props: {
    },
    data() {
        return {
        };
    },
};
</script>
