var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--mypage"
  }, [_c('v-container', [_c('div', {
    staticClass: "mypage-container"
  }, [_c('mypage-navigation'), _c('div', {
    staticClass: "mypage-contents"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--unberline"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("회원탈퇴")])]), _c('v-tabs-items', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "info"
    }
  }, [_c('div', [_c('v-card', {
    staticClass: "grey-ef",
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-24 pa-md-30"
  }, [_c('div', {
    staticClass: "mb-10 mb-md-14"
  }, [_c('h4', {
    staticClass: "tit tit--xs primary--text"
  }, [_vm._v("* 회원탈퇴란?")])]), _c('ul', {
    staticClass: "dot-list dot-list--large"
  }, [_c('li', [_vm._v("KATA Auction 사이트에 대한 이용해지를 의미합니다.")]), _c('li', [_vm._v("KATA Auction에서 발송하는 광고성 이메일의 경우 회원탈퇴 요청 접수 후 24시간 이내에 발송이 중지됩니다.")]), _c('li', [_vm._v("회원탈퇴 요청 후 48시간이 지난 후, 광고성 이메일/SMS를 접수하시는 경우, 당사에 연락하여 주시기 바랍니다.")])])])], 1)], 1), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_c('div', {
    staticClass: "mb-10 mb-md-20"
  }, [_c('div', {
    staticClass: "mb-6 mb-md-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("회원정보")])]), _c('v-divider', {
    staticClass: "primary border-2"
  })], 1), _c('ul', {
    staticClass: "letter-list"
  }, [_c('li', [_c('em', [_vm._v("-")]), _c('span', [_vm._v("회원탈퇴 시 보유하고 계신 쿠폰 및 적립금은 즉시 소멸되며 동일한 아이디로 재가입 하더라도 복원되지 않습니다.")])]), _c('li', [_c('em', [_vm._v("-")]), _c('span', [_vm._v("회원탈퇴가 완료되더라도 판/구매자의 권익을 보호하기 위해 다음과 같이 회원정보가 일정기간 보존됨을 알려드립니다.")])])])]), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_c('v-card', {
    staticClass: "grey-ef",
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-24 pa-md-30"
  }, [_c('ul', {
    staticClass: "dot-list dot-list--large"
  }, [_c('li', [_vm._v("관계 법령에 의거하여 보존이 필요한 경우에 한하여 보존됩니다.")]), _c('li', [_vm._v("계약 및 청약철회 등에 관한기록 : 5년")]), _c('li', [_vm._v("대금결제 및 재화등의 공급에 관한 기록 : 5년")]), _c('li', [_vm._v("소비자의 불만 또는 분쟁처리에 관한 기록 : 3년")]), _c('li', [_vm._v("부정이용 등에 관한 기록 : 1년")])])])], 1)], 1), _c('div', {
    staticClass: "mt-30 mt-md-50"
  }, [_c('div', {
    staticClass: "v-btn--group v-btn--group--large"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-220px",
    attrs: {
      "x-large": "",
      "color": "primary",
      "to": "/mypage/leave/action"
    }
  }, [_vm._v("탈퇴")])], 1)])]), _c('v-tab-item', {
    attrs: {
      "value": "action"
    }
  }, [_c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("탈퇴 정보")])]), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('myinfo-modify-row', {
    attrs: {
      "label": "탈퇴 사유"
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "type": "text",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.withdrawalReason,
      callback: function ($$v) {
        _vm.withdrawalReason = $$v;
      },
      expression: "withdrawalReason"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "남기실 말씀"
    }
  }, [_c('v-textarea', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.withdrawalMessage,
      callback: function ($$v) {
        _vm.withdrawalMessage = $$v;
      },
      expression: "withdrawalMessage"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-30 mt-md-50"
  }, [_c('div', {
    staticClass: "v-btn--group v-btn--group--large"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-220px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "color": "grey-cb",
      "to": "/mypage/leave"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("취소")])]), _c('v-btn', {
    staticClass: "w-100 mw-220px",
    attrs: {
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.withdraw
    }
  }, [_vm._v("탈퇴신청")])], 1)])])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }