<template>
    <my-page tit="작성 글">
        <div class="mb-8 mb-lg-16">
            <v-row class="row--x-small search--primary">
                <v-spacer></v-spacer>
                <v-col cols="12" md="auto">
                    <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-120px" outlined hide-details @input="search(true)"></v-select>
                </v-col>
                <v-col cols="9" md="auto">
                    <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." @input="search(true)"/>
                </v-col>
                <v-col cols="3" md="auto">
                    <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6">
                        <span class="white--text font-size-14" @click="search()">검색</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-tabs color="grey-9" hide-slider class="v-tabs--punch mb-20 mb-lg-40">
            <v-tab @click="input('community')">전체</v-tab>
            <v-tab v-for="category, index in categories" :key="index" @click="input(category.code)">{{ category.name }}</v-tab>
        </v-tabs>
        <table class="board-list board-list--main">
            <thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:100px;">카테고리</th>
                    <th style="width:62%;">제목</th>
                    <th style="width:11%;">작성일자</th>
                    <th style="width:11%;">조회수</th>
                    <th style="width:11%;">댓글수</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="board in boards" :key="board._id" @click="move(board._id)">
                    <td align="center" class="board-list__txt board-list__txt--first">
                        <v-chip color="primary-50" text-color="primary">
                            {{ board?.category?.name }}
                        </v-chip>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="board-list__tit">
                            <p class="grey-6--text page-text ellip">
                                {{ board?.subject }}
                            </p>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt">
                        <span class="page-text page-text--sm">{{ $dayjs(board?.createdAt).format("YYYY.MM.DD") }}</span>
                    </td>
                    <td align="center" class="board-list__txt">
                        <!-- <board-count-view /> -->
                        <div class="d-flex align-center" :class="{ 'justify-end': justifyEnd, 'justify-center': !justifyEnd }">
                            <i class="icon icon-view mr-4 mr-lg-8"></i>
                            <p class="page-text--sm grey-9--text">
                                {{ board?.viewCount }}
                            </p>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt">
                    <board-count-comment :commentCount="board.commentCount"/>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search()"/>
        </div>
    </my-page>
</template>

<script>
import api from '@/api'
import MyPage from "@/pages/client/templates/MyPage.vue";
import SearchPrimary from "@/components/client/board/search--primary.vue";
import BoardCountView from "@/components/client/board/board-count--view.vue";
import BoardCountComment from "@/components/client/board/board-count--comment.vue";

export default {
    components: {
        MyPage,
        SearchPrimary,
        BoardCountView,
        BoardCountComment,
    },
    props: {
    },
    data() {
        return {
            page: 1,
            limit: 6,
            pageCount: 1,
            categories: null,

            filter: {
                code: this.$route.query.code || "community",
                searchKey: this.$route.query.searchKey || "subjectcontent",
                searchValue: this.$route.query.searchValue || null,
                // isApproval: this.$route.query.isApproval || null,
                // _category: this.$route.query._category || null
            },

            searchKeys: [
                { text: "제목+내용", value: "subjectcontent" },
                { text: "제목", value: "subject" },
            ],

            boards: null
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { categories } = await api.v1.boards.categories.gets({ params: { depth: 1, code: "community" } });
            console.log(categories);
            this.categories = categories[0].children
            await this.search()
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
                return;
            }
        
            var { summary, boards } = await api.v1.me.boards.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: {...this.filter}
            });

            this.boards = boards;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        input(code) {
            this.filter.code = code;
            this.$router.push({
                query: { ...this.filter }
            })
            this.search();
        },
        move(_id) {
            this.$router.push(`/mypage/community/${_id}`)
        }
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        },
        logon(){ 
            return !!this.accessToken 
        },
    }
};
</script>
