var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('my-page', {
    attrs: {
      "tit": "설문조사 결과"
    }
  }, [_c('div', _vm._l(_vm.results, function (result) {
    var _result$survey, _result$survey2, _result$survey3;
    return _c('v-card', {
      key: result._id,
      staticClass: "survey-card pa-16 pa-md-20 border-bottom",
      on: {
        "click": function ($event) {
          return _vm.$router.push(`/content/survey/result/${result === null || result === void 0 ? void 0 : result._id}`);
        }
      }
    }, [_c('v-row', {
      staticClass: "flex-lg-nowrap",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto",
        "order": "1",
        "order-lg": "1"
      }
    }, [_c('v-card', {
      attrs: {
        "rounded": "",
        "color": "transparent",
        "width": "258",
        "max-width": "100%"
      }
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": result === null || result === void 0 ? void 0 : (_result$survey = result.survey) === null || _result$survey === void 0 ? void 0 : _result$survey.thumb,
        "aspect-ratio": 258 / 146
      }
    })], 1)], 1), _c('v-col', {
      staticClass: "survey-details mt-10 mt-lg-0",
      attrs: {
        "cols": "12",
        "lg": "",
        "order": "3",
        "order-lg": "2"
      }
    }, [_c('v-row', {
      staticClass: "row--x-small"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', {
      attrs: {
        "color": "primary-50",
        "text-color": "primary"
      }
    }, [_vm._v(_vm._s(result === null || result === void 0 ? void 0 : (_result$survey2 = result.survey) === null || _result$survey2 === void 0 ? void 0 : _result$survey2.category))])], 1), _c('v-col', [_c('h4', {
      staticClass: "tit tit--sm font-weight-medium ellip"
    }, [_vm._v(" " + _vm._s(result === null || result === void 0 ? void 0 : (_result$survey3 = result.survey) === null || _result$survey3 === void 0 ? void 0 : _result$survey3.subject) + " ")])])], 1), _c('p', {
      staticClass: "page-text grey-6--text mt-8 mt-lg-16"
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(result === null || result === void 0 ? void 0 : result.createdAt).format("YYYY.MM.DD")) + " ")])], 1), _c('v-col', {
      attrs: {
        "cols": "auto",
        "order": "2",
        "order-lg": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "elevation": "5",
        "rounded": "",
        "color": "transparent",
        "width": "146",
        "max-width": "100%"
      }
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(result === null || result === void 0 ? void 0 : result.content)
      }
    })])], 1)], 1)], 1);
  }), 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.init();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }