<template>
    <client-page class="wrap--mypage">
        <!-- 서브비주얼 -->
        <!-- <sub-visual visual="마이페이지" /> -->

        <v-container>
            <div class="mypage-container">
                <!-- 네비게이션 메뉴 -->
                <mypage-navigation></mypage-navigation>
                <!-- 페이지 콘텐츠 -->
                <div class="mypage-contents">
                    <div class="tit-wrap tit-wrap--unberline">
                        <h2 class="tit">회원탈퇴</h2>
                    </div>
                    <v-tabs-items :value="tab">
                        <!-- S:안내탭 -->
                        <v-tab-item value="info">
                            <div>
                                <v-card tile elevation="0" class="grey-ef">
                                    <v-card-text class="pa-24 pa-md-30">
                                        <div class="mb-10 mb-md-14">
                                            <h4 class="tit tit--xs primary--text">* 회원탈퇴란?</h4>
                                        </div>
                                        <ul class="dot-list dot-list--large">
                                            <li>KATA Auction 사이트에 대한 이용해지를 의미합니다.</li>
                                            <li>KATA Auction에서 발송하는 광고성 이메일의 경우 회원탈퇴 요청 접수 후 24시간 이내에 발송이 중지됩니다.</li>
                                            <li>회원탈퇴 요청 후 48시간이 지난 후, 광고성 이메일/SMS를 접수하시는 경우, 당사에 연락하여 주시기 바랍니다.</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </div>

                            <div class="mt-30 mt-md-40">
                                <div class="mb-10 mb-md-20">
                                    <div class="mb-6 mb-md-10">
                                        <h3 class="tit tit--xs">회원정보</h3>
                                    </div>
                                    <v-divider class="primary border-2"></v-divider>
                                </div>
                                <ul class="letter-list">
                                    <li><em>-</em><span>회원탈퇴 시 보유하고 계신 쿠폰 및 적립금은 즉시 소멸되며 동일한 아이디로 재가입 하더라도 복원되지 않습니다.</span></li>
                                    <li><em>-</em><span>회원탈퇴가 완료되더라도 판/구매자의 권익을 보호하기 위해 다음과 같이 회원정보가 일정기간 보존됨을 알려드립니다.</span></li>
                                </ul>
                            </div>

                            <div class="mt-30 mt-md-40">
                                <v-card tile elevation="0" class="grey-ef">
                                    <v-card-text class="pa-24 pa-md-30">
                                        <ul class="dot-list dot-list--large">
                                            <li>관계 법령에 의거하여 보존이 필요한 경우에 한하여 보존됩니다.</li>
                                            <li>계약 및 청약철회 등에 관한기록 : 5년</li>
                                            <li>대금결제 및 재화등의 공급에 관한 기록 : 5년</li>
                                            <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                                            <li>부정이용 등에 관한 기록 : 1년</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </div>

                            <!-- 하단버튼 -->
                            <div class="mt-30 mt-md-50">
                                <div class="v-btn--group v-btn--group--large">
                                    <v-btn x-large color="primary" class="w-100 mw-220px" to="/mypage/leave/action">탈퇴</v-btn>
                                </div>
                            </div>
                        </v-tab-item>
                        <!-- E:안내탭 -->

                        <!-- S:인풋탭 -->
                        <v-tab-item value="action">
                            <div class="mb-10">
                                <h3 class="tit tit--xs">탈퇴 정보</h3>
                            </div>
                            <div class="table-style table-style--default">
                                <myinfo-modify-row label="탈퇴 사유">
                                    <v-text-field v-model="withdrawalReason" type="text" outlined hide-details class="w-100 mw-lg-500px" />
                                </myinfo-modify-row>
                                <myinfo-modify-row label="남기실 말씀">
                                    <v-textarea v-model="withdrawalMessage" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                                </myinfo-modify-row>
                            </div>

                            <!-- 하단버튼 -->
                            <div class="mt-30 mt-md-50">
                                <div class="v-btn--group v-btn--group--large">
                                    <v-btn x-large outlined color="grey-cb" class="w-100 mw-220px" to="/mypage/leave"><span class="primary--text">취소</span></v-btn>
                                    <v-btn x-large color="primary" class="w-100 mw-220px" @click="withdraw">탈퇴신청</v-btn>
                                </div>
                            </div>
                        </v-tab-item>
                        <!-- E:인풋탭 -->
                    </v-tabs-items>
                </div>
            </div>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";
import MypageNavigation from "../../../components/client/mypage-navigation.vue";
import api from "@/api";

export default {
    components: {
        ClientPage,
        MyinfoModifyRow,
        MypageNavigation,
    },
    props: {
        tab: { type: String, default: "info" },
    },
    data: function() {
        return {
            leave: "main",

            withdrawalReason: null,
            withdrawalMessage: null,
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        tab() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
    methods: {
        init() {
            if (!this.accessToken) {
                alert("로그인 이후 이용가능합니다");
                this.$router.push("/login");
                return;
            }
        },
        async withdraw() {
            await api.v1.me.withdraw({
                withdrawalReason: this.withdrawalReason,
                withdrawalMessage: this.withdrawalMessage,
            });

            alert("탈퇴처리 되었습니다");

            this.$store.dispatch("logout").then(() => {
                this.$router.push("/");
            });
        },
    },
};
</script>
