var render = function render(){
  var _vm$board, _vm$board$category, _vm$board2, _vm$board3, _vm$board4, _vm$board5, _vm$board6, _vm$board7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('section', {
    staticClass: "section section--last"
  }, [_c('v-container', [_c('div', [_c('div', {
    staticClass: "board__view mb-40 mb-lg-60"
  }, [_c('div', {
    staticClass: "board__view__row"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align-md": "center",
      "justify-md": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": "primary-50",
      "text-color": "primary"
    }
  }, [_vm._v(_vm._s((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : (_vm$board$category = _vm$board.category) === null || _vm$board$category === void 0 ? void 0 : _vm$board$category.name))])], 1), _c('v-col', [_c('p', {
    staticClass: "page-text font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : _vm$board2.subject) + " ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-end align-center"
  }, [_c('i', {
    staticClass: "icon icon-clock mr-4 mr-lg-8"
  }), _c('p', {
    staticClass: "page-text page-text--sm grey-9--text"
  }, [_vm._v(" " + _vm._s(_vm.$dayjs((_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : _vm$board3.createdAt).format("YYYY.MM.DD")) + " ")])])])], 1)], 1), _c('div', {
    staticClass: "board__view__row"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap justify-end align-center"
  }, [_c('div', {
    staticClass: "d-flex align-center mr-8 mr-lg-16"
  }, [_c('p', {
    staticClass: "page-text--sm grey-9--text mr-4 mr-lg-8"
  }, [_vm._v("작성자 :")]), _c('board-user-level', {
    attrs: {
      "user": (_vm$board4 = _vm.board) === null || _vm$board4 === void 0 ? void 0 : _vm$board4.user,
      "textStyle": "page-text--sm grey-9--text"
    }
  })], 1), _c('p', {
    staticClass: "page-text--sm grey-9--text"
  }, [_vm._v(" 승인여부: "), _c('span', [_vm._v(_vm._s((_vm$board5 = _vm.board) === null || _vm$board5 === void 0 ? void 0 : _vm$board5.approvalStatus))])])])]), _c('div', {
    staticClass: "board__view__row board__contents"
  }, [_c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-lg-40px",
    attrs: {
      "color": "grey-6",
      "to": `/mypage/column/${(_vm$board6 = _vm.board) === null || _vm$board6 === void 0 ? void 0 : _vm$board6._id}/edit`
    }
  }, [_c('span', {
    staticClass: "white--text font-size-14"
  }, [_vm._v("수정")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-lg-40px",
    attrs: {
      "outlined": "",
      "color": "grey-b3"
    },
    on: {
      "click": _vm.remove
    }
  }, [_c('span', {
    staticClass: "grey-6--text font-size-14"
  }, [_vm._v("삭제")])])], 1)], 1)], 1), _c('div', {
    staticClass: "board__content mb-20 mb-lg-40",
    domProps: {
      "innerHTML": _vm._s((_vm$board7 = _vm.board) === null || _vm$board7 === void 0 ? void 0 : _vm$board7.content)
    }
  }), _c('board-recommend', {
    attrs: {
      "board": _vm.board
    },
    on: {
      "init": _vm.set
    }
  })], 1)])])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }